@import '../../../src/styles/media';
@import '../../../src/styles/zIndex';

.verticalMenu__canvas {
	position: absolute;
	top:0;
	left:0;
	width: 100vw;
	height: 100vh;
	background-color:rgba(255, 255, 255, 0);
	z-index: z-index(modal);
    display: flex;
    justify-content: flex-end;
}


.verticalMenu {
	display: flex;
	flex-direction: column;
	//right:0; using justify-content: flex-end; in .verticalMenu__canvas instead
	margin-top: 4.0rem;
	width: 8.6rem;
	height: 100%;
	background-color: #FAFAFA;
	z-index:1100;
	box-shadow: 0 10px 15px rgba(0,0,0,.1);
	transition: box-shadow 0.4s ease-in-out;
	&__menu-item {
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 8.6rem;
		height: 6.5rem;
		border-bottom: 1px solid #F5F5F5;
		& a {
			text-decoration:none;
		}
		.fa {
			color: #999999;
			font-size: 2.56rem;
		}
		&--label {
			color: #999999;
			font-size: 0.96rem;
		}
		&:hover {
			background-color:#00BAFF;
			.fa,
			.verticalMenu__menu-item--label {
				color:#FFFFFF;
				stroke: #FFFFFF;
				fill: #FFFFFF;
			}
		}
	}

}
