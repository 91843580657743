@import '../../../src/styles/media';
@import '../../../src/styles/zIndex';

.summaryPanel {
	/*	min-width: 100px; to allow scaling. Turned off as flex moments made it shrink at all screen resolutions
		*/
    height: calc(100vh - 61px);

	background-color:#FFFFFF;
	position: fixed;
	top: 61px;
	z-index: z-index(forward);

	@media ($tablet) {
		display: flex;
		flex-direction: row;
		width: 100%;
		height: 125px;
		box-shadow: 0 3px 15px rgba(0,0,0,.2);
		transition: box-shadow 0.4s ease-in-out;

		.app:not(.app--signed-in) & {										// SP FROM TOP 1/4 not signed in
			top: 110px;
		}

		.app.app--signed-in &:not(.summaryPanel--author) {					// SP FROM TOP 2/4 signed in but not users' LTL
			top: 61px;
		}
	}
}

.app .summaryPanel--author.summaryPanel:not(.summaryPanel--no-min) {		// SP FROM TOP 3/4 Author but nto a new record
	@media ($tablet) {
		top: 101px;
	}
	@media ($mobile-l) {
		&.summaryPanel--edit {												// SP FROM TOP 4/4 when in phone edit, allow scrolling as keyboard takes up a lot of screen
			position: relative;
			top: 40px;
		}
	}
}

.summaryPanel--loading {
	width: 364px;
	text-align: center;
	margin-top:30px;
	color:#888;
}

.summaryPanel__allAndDateMenu {
	display: flex;
	flex-direction: row;
	width:464px;
	@media ($tablet) {
		flex-direction: column;
		width: 100%;
	}
}

.summaryPanel__activity-feed {
	flex: 0 0 auto;
	background-color:#FFF;
	width:364px;
	@media ($tablet) {
		width: 100%;
	}
}


/*
.summaryPanel__activity-feed					{	background-color:rgba(255, 0, 212, 0.5);	}
.summaryPanel__imageDataDescriptionAndFeed 		{	background-color:rgba(255, 251, 0, 0.5);	}
.summaryPanel__imageDataAndDescription			{	background-color:rgba(0, 255, 21, 0.5);		}
.summaryPanel__imageAndData						{	background-color:rgba(1, 103, 255, 0.5);	}
.summaryPanel__description						{	background-color:rgba(255, 136, 0, 0.5);	}
.summaryPanel__data 							{ 	background-color:rgba(146, 87, 255, 0.5);	}
.summaryPanel__datesAndInteractions 			{	background-color:rgba(0, 255, 221, 0.5);	}
.summaryPanel__interactions 					{	background-color:rgba(255, 140, 0, 0.5);	}
.summaryPanel__layoutTEMP--interaction 			{	background-color:rgba(255, 0, 0, 0.5);		}
.summaryPanel__dataAndDescription 				{	background-color:rgba(255, 140, 0, 0.5);	}
.summaryPanel__title							{	background-color:rgba(87, 207, 255, 0.5);	}
.summaryPanel__dates 							{	background-color:rgba(87, 255, 109, 0.5);	}
.summaryPanel__date 							{	background-color:rgba(255, 87, 95, 0.5);	}
.summaryPanel__description 						{	background-color:rgba(241, 87, 255, 0.5);	}
.dateMenu										{	background-color:rgba(0, 186, 255, 0.5);	}
*/

/* The summary image styles are the default for the IMG component so no styles here to nest this in */
@media ($tablet) {
	.summaryPanel__image {
		width: 166px;
		height: 125px;
		flex: 0 0 auto;
	}

	.summaryPanel__image .formImage__hover {
		height: 125px;
	}

	.summaryPanel__image .formImage__icon {
		font-size: 40px;
	}

	.summaryPanel__image .formImage--push-to-height {
		height: 125px;
	}

	.summaryPanel__image .formImage--push-to-width {
		width: 166px;
	}
}

.summaryPanel__data { 							// div containing the title, dates and description
	width: 100%;
	margin:0;
	padding:10px 10px;
	@media ($tablet) {
		margin:0;
		padding-top:5px;
	}
}

.summaryPanel__imageDataDescriptionAndFeed {
	display: flex;
    flex: 0 0 auto;
	flex-direction: column;
	width: 364px;
	@media ($tablet) {
		width: 100%;
	}
}

.summaryPanel__imageDataAndDescription {
	display: flex;
	flex-direction: column;
	z-index: z-index(foreground);
}

.summaryPanel__imageAndData {
	//width: 100%;
	display: flex;
	flex-direction: column;
	flex: 1 0 auto;
	@media ($tablet) {
		flex-direction: row;
		//max-width: calc(50% + 83px); // Technically 50% + 83px gives half ro data and half to descr. Give a little more to descr.
	}
	@media ($mobile-l) {
		width:100%; // Important top push back to 100% as the tablet view shares this space with description so it is reduced there and needs to be pushed back out here.
	}
}

.summaryPanel__dataAndInteractions {
	position: relative;
	display: flex;
	flex-direction: row;
	flex: 1 1 auto;
	justify-content: space-between;
	@media ($tablet) {
		flex-direction: column;
	}

	.summaryPanel__button {
		font-size:1.2rem;
		padding: 7px 14px;
		margin: 0 0 5px 10px;
	}
}

.summaryPanel__interactions {
	display: flex;
	flex-direction: row;
	align-self: flex-end;
	margin-right:8px;
	margin-left:10px;
	@media ($tablet) {
		align-self: flex-start;
	}
}

.summaryPanel__interaction { 		//TASK: change to icons such as like etc. Add in extra menu items for tablet and lower for dates and description
	padding:5px;
	margin-right:5px;
	cursor: pointer;
	.fa {
		color: #999999;
		font-size: 15.6px;
		position: relative;
		&:hover {
			color: #00baff;
			cursor: pointer;
		}
		&--active {
			color: #00baff;
		}
	}
	&.share {						// remove positioning and allow inheritance of other styles. see AP-214
		display: inline-block;
		position: relative;
		top: unset;
		right: unset;
		font-size: unset;

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { // early IE (10/11) hack
			top: -3px;
			right: 0;
		}
	}
}

.summaryPanel__title {
	flex: 1 1 auto;
	width:100%;
	font-size:14pt;
	color:#555;
	text-align:left;
	padding: 5px 5px 1px 5px;
	.input {
		font-size:14pt;
		color:#555;
		width:100%;
	}
	&--editMode {
		padding: 0;
	}
}

.summaryPanel__dates {
	font-size: 9pt;
	color: #AAA;
	text-align: left;
	margin-bottom:0;
	padding: 1px 5px 5px 5px;
	min-width: 18rem;

	&--editMode {
		padding: 0;
	}

	& .input__error {
		position: absolute;
		bottom: -1.6rem;
		width: 35rem;
	}
}

.summaryPanel__date {
	color:#AAA;
	width: auto;
	display: inline-block;
}

.summaryPanel__date .input {
	width: 75px;
	font-size: 9pt;
	padding-left:5px;
	padding-right:5px;
	color:#AAA;
}

.summaryPanel__date:first-child {
	margin-right: 0.5rem;
}

.summaryPanel__date:last-child {
	margin-left: 0.5rem;
}

.summaryPanel__descriptionPanel {
	flex: 1 0 auto;
	z-index: z-index(foreground);
	width:100%;
	@media ($tablet) {
		padding:5px 5px 5px 10px;
		background-color: rgba(255, 255, 255, 0.95);
	}
}
.summaryPanel__description {
	//width: calc(100% - 16px);
	height: calc(100% - 8px);
	white-space: pre-line;
	font-size: 10pt;
	line-height: 17.6px;
	color: #666666;
	margin: 3px 10px;
	@media ($tablet) {
		height: calc(100% - 8px);
		max-width:60%;					// save reading right across tablet width
	}
	@media ($mobile-l) {
		max-width: calc(100% - 8px);	// go back to full width now we are below tablet size
		margin: 3px;
	}
}


.summaryPanel__description .input {
	//width: calc(100% - 8px);
	min-height: 220px;
	resize: both;
	font-family: sans-serif;
	line-height:17.6px;
	padding:5px;
	border: 1px solid #D5D5D5;
}

.summaryPanel__field {
	position: relative;
	outline: none;
	margin-bottom: 5px;

	& .input {
		margin: 0 0 -1px 0;
		padding: 4px 5px 5px 4px;
		outline: none;
		border: 1px solid #E5E5E5;
	}
}


.summaryPanel__indicator {
	font-size: 12.8px;
	color:#DDD;
	margin-left:20px;
	width:20px;
	text-align:center;
}

.summaryPanel__buttons {
	display: flex;
	justify-content: flex-end;
	margin: 0.5rem 1rem 0 0.5rem;
}

.summaryPanel__button {
	margin-right: 1rem;
}
