

$primary-colour: #00BAFF;
$primary-colour-deep: #0098DD;
$primary-colour-dark: #0076BB;
$go-colour:	rgb(81, 177, 3);

// jbc-theme : jbc
$jbc-primary-colour: #e13026;
$jbc-primary-colour-deep: #c72b22;
$jbc-primary-colour-dark: #a1211a;
$jbc-go-colour:	rgb(81, 177, 3);

// gla-theme : glenaeon
$gla-primary-colour: #bb03bb;
$gla-primary-colour-deep: #a001a0;
$gla-primary-colour-dark: #810181;
$gla-go-colour:	rgb(81, 177, 3);

