@import '../../../src/styles/media';

.homepartner-page {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100vh;
}

.main-strip {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #F0F0F0;
	//min-height: 14rem;
	overflow: hidden;
	// @media($mobile-l) {
	// 	display: none;
	// }
}
.main-strip__centred {
	display: flex;
	flex: 0 1 auto;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	max-width: 1200px;
	//background-color: rgba(100, 100, 100, 0.3);
	//height:100%;
	//min-width: 420px;  // screen will shrink below this width
}
.main-strip__pic {
	max-width: 165px;
	max-height: 140px;
	min-width: 108px;
	min-height: 92px;
	flex: 0 1 auto;
}

.main-strip__pic IMG {
	height:auto;
	max-width: 165px;
	max-height: 140px;
	min-width: 108px;
	min-height: 92px;
	@media ($mobile-l) {
		width: 34vw;
		height: auto;
	}
	@media ($mobile-s) {
		width: 34vw;
		height: auto;
	}
}


.partner-manager-profile-pic {
	max-height: 140px;
	border-bottom: solid 1px #EFEFEF;
}


.main-strip__note,
.main-strip__pitch {
	display: flex;
	flex-direction: column;
	flex: 1 1 20%;
	text-align: left;
	padding: 10px;
}
@media ($desktop-m) {
	.main-strip__pitch {
		display: none;
	}
}



.main-strip__pitch {
	text-align: center;
}

.main-strip__note H1,
.main-strip__pitch H1 {
	margin: 1px 0 2px 0;
	font-size: 1.9rem;
	@media ($desktop-l) {
		font-size: 1.7rem;
		//color: orange;
	}
	// @media ($desktop-m) {
	// 	color: red;
	// }
	// @media ($desktop-s) {
	// 	color: blue;
	// }
	// @media ($tablet) {
	// 	color: green;
	// }
	@media ($mobile-l) {
		font-size: 1.1rem;
		//color: rgb(128, 73, 0);
	}
}

.main-strip__note H2,
.main-strip__pitch H2 {
	margin: 1px 0;
	font-size: 1.5rem;
	font-weight: normal;
	font-style: italic;
	color: #555;
	@media ($desktop-l) {
		font-size: 1.4rem;
	}
	@media ($mobile-l) {
		font-size: 1.2rem;
	}
	@media (max-width: 414px) {
		display: none;
	}
}

.main-strip__note P,
.main-strip__pitch P {
	color: #333;
	font-size: 1.5rem;
	margin: 0;
	padding: 0;
	.regMark {
		font-size: 0.8rem;
		vertical-align: super;
	}
	@media ($desktop-l) {
		font-size: 1.35rem;
	}
	@media ($mobile-l) {
		font-size: 1.1rem;
	}
}

.main-strip__logo--svg--box {
	display: flex;
	flex: 0 1 auto;
	height:100%;
	padding: 8px 20px;
}
.main-strip__logo--svg {
	height: 10vw;
	max-height: 120px;
	display: flex;
	flex: 0 1 auto;
	fill: #00baff;
	padding-top: .5vw;
}


@media (max-width: 34em) {
	.main-strip__logo--svg--box {
		display: none;
		background-color: rgba(0, 255, 255, .1);
	}
	.main-strip__logo--svg{
		display: none;
		background-color: rgba(0, 255, 255, .1);
	}
}



.home-actions {
	display: flex;
	margin-top: 2rem;
	min-height: 10rem;
	flex-direction: row;
	justify-content: center;
	max-width: 100%;
}

.home-actions__centred {
	display: flex;
	min-height: 5rem;
	max-width:1200px;
	padding:0 50px;
	@media ($tablet) {						// below table size, switch actions to column layout (one action above the next)
		flex-direction: column;
		min-height: 5rem;
		padding:0 10px;
	}
}
.home-actions__action {
	display:flex;
	flex-direction: row;
	margin: 1rem;
	cursor: pointer;
	max-width:346px;
	text-decoration: none;
	@media ($desktop-m) {					// below desktop-m size, switch action CONTENT to column layout (Icon above text block)
		flex-direction: column;
		align-items: center;
	}
	@media ($tablet) {						// below tablet size, switch action CONTENT BACK to row layout (Icon next to text block)
		flex-direction: row;
		align-items: start;
	}
}
.home-actions__action .fa-stack {
	flex: 0 0 auto;
	color: #999;
}

.home-actions__action .fa-circle {
	color: #DDD;
}

.home-actions__action:hover .fa-circle {
	color: #00BAFF;
}

.home-actions__action:hover .fa-stack {
	color: #FFF;
}

.home-actions__action--textBlock {
	display:flex;
	flex-direction: column;
	margin-left: 2rem;
	margin-right: 4rem;
	width: 100%;
	@media ($desktop-m) {
		align-items: center;
	}
	@media ($tablet) {
		margin-left: 1rem;
		margin-right: 1rem;
		align-items: left;
	}
}

.home-actions__action h2 {
	font-size: 1.92rem;
	color: #333;
	margin: 0.5rem 0;
	@media ($desktop-m) {
		text-align: center;
	}
	@media ($tablet) {
		text-align: left;
	}
}

.home-actions__action p {
	font-size: 1.44rem;
	color: #999;
	margin-top: 0.5rem;
}

.home-records__wrapper {
	display: flex;
	justify-content: center;
}

.home-records {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	height: auto;
	min-width: 30.7rem;
	max-width: 130rem;
	margin-top: 14px;
}

.home-records__spinner {
	margin-bottom: 2rem;
}

.homeRecordBox {
	width: 28rem;
	min-width: 28rem;
	height: 26rem;
	background-color: #AAA;
	margin: 13.33px;
	padding: 0;
	border-radius: 0.6rem;
	background-color: #FFF;
}

.homeRecordBox .recordBox__frame--top {
	width: 28rem;
	height: 21rem;
	border-radius: 0.6rem 0.6rem 0 0;
	overflow: hidden;
	.formImage__wrapper {
		height: 21rem;
	}
}

.homeRecordBox .recordBox__frame--base {
	width: 28rem;
	border-radius: 0 0 0.6rem 0.6rem;
	background-color: #F5F5F5;
}

.homeRecordBox .recordBox__fullName {
	font-size: 12pt;
	color: #000;
	padding: 0.6rem 1rem;
}

.homeRecordBox .recordBox__years {
	font-size: 10pt;
	color: #666;
	padding: 0 1rem 7px 1rem;
}

.homeRecordBox .recordBox__image {
	max-width: 28rem;
}

.homeRecordBox .formImage--push-to-width {
	width: 28rem;
}

/* LAYOUT TESTING ##################################################
.main-slider--centred {	background-color:rgb(146, 204, 141);  }
.main-slider__logo {	background-color:rgb(70, 109, 161); }
.mainContent { background-color:rgb(204, 141, 190); }
.mainContent--centred {	background-color:rgb(146, 204, 141); }
.mainContent__actions {	background-color:rgb(35, 161, 161);  }
.home-actions__action { background-color:rgb(221, 241, 255); }
.home-actions__action { background-color:rgb(221, 241, 255); }
.home-actions__action .fa-stack { background-color:rgb(221, 100, 255); }
.home-actions__action--textBlock { background-color:rgb(100, 241, 255); }
.home-records {	background-color:rgb(170, 163, 64); }
.homeRecordBox { background-color:rgb(255, 4, 4); }
*/
/* LAYOUT TESTING ################################################## */


/* BROWSER SPECIFIC ################################################## */
/* TASK: Remove the IE 10+ hack below */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.home-actions__action {
		width: calc( ( (100% - 4rem)/3 ) - 1px );
	}
}
/* TASK: Remove the IE Edge hack below */
@supports (-ms-ime-align: auto) {
	.home-actions__action {
		width: calc( ( (100% - 4rem)/3 ) - 1px );
	}
}
/* BROWSER SPECIFIC ################################################## */




@media ($tablet) {
	.home-records {
		max-width: 100%;
	}
}

