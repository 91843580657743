@import '../../../src/styles/zIndex';

.swiper {
	position: absolute;
	top: 0;
	left: 0;
	z-index: z-index(forward);
	width: 100%;
	height: 100%;
}
