@import '../../../../../src/styles/media';

.modal.delete-moment {
	display: flex;
	flex-direction: column;

	padding: 3rem;
	width: unset;
	height: 40rem;

	margin-top: 0;
	align-self: center;
}

.delete-moment__view {
	display: flex;
	flex-direction: column;
	height: 29rem;
	margin-bottom: 2rem;

	@media ($tablet) {
		height: 30rem;
	}
}

.delete-moment__image {
	margin-bottom: 2rem;
}

.delete-moment__buttons {
	display: flex;
	justify-content: flex-end;
}

.delete-moment__button {
	color: #FFFFFF;
	background-color: #CC0000;
	margin-left: 1.5rem;
}

.delete-moment__title {
	color: #CC0000;
	font-size: 2.4rem;
	margin-bottom: 1rem;
}

.delete-moment__title i {
	margin-right: 1rem;
}
