
.input {
	&::placeholder {
		color: #BBB;
	}
}

.input-wrapper {
	display: flex;
	flex-direction: column;
}

.input:focus:not([type="checkbox"]) {
	border-color: rgba(0, 186, 255, 0.8);
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(0, 186, 255, 0.6);
	outline: 0 none;
	border:solid 1px #00BAFF;
}

.input__error {
	min-width: 170px;
	color: rgb(230, 50, 0);
	margin-top: 5px;
}

.input__label {
	min-height: 1.9rem;
	display: flex;
	align-items: flex-end;
	font-size: 1.3rem;
	&--required {
		color:#00BAFF;
		font-size: 1.0rem;
		padding-bottom: 8px;
		padding-left: 3px;
	}
}

.input-switch__wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 3.6rem;
	outline: none;
}

.input__icon {
	position: relative;

	.input {
		width: 100%;
	}

	&:after {
		font-family: FontAwesome;
		font-style: normal;
		font-size: 3rem;
		position: absolute;
		right: 0.6rem;
		top: 0.6rem;
		height: 2.5rem;
		width: 3.8rem;
		line-height: 2.5rem;
	}

	&--visa:after {
		content: '\f1f0';
		color: #1c2e9b;

	}

	&--mastercard:after {
		content: '\f1f1';
		color: #555;
		background: linear-gradient(to right, #cc0000 40%, #ffa500 60%);
		border-radius: 0.5rem;
	}

	&--amex:after {
		content: '\f1f3';
		color: rgb(36, 110, 169);
	}
}
