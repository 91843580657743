@import '../../../../src/styles/media';

.textInput {
	flex: 1;
	border-radius: 5px;
	color: #999;
	position: relative;
	padding: 0;
	padding-left: 10px;
	padding-top: 2px;
	height: 48px;
	font-size: 12pt;
	outline: none;
	background-color: #FFFFFF;
	border: 1px solid #DDDDDD;
	border-radius: 5px;

	-webkit-appearance:none;

	&--hasIcon {
		padding-left: 48px;
	}
	&::placeholder {
		color: #BBB;
	}
}


/* V2.0 blue glow around focused input boxes RMN */
.textInput:focus {
	border-color: rgba(0, 186, 255, 0.8);
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(0, 186, 255, 0.6);
	outline: 0 none;
}

.textInputWrapper {
	display: flex;
	flex-direction: column;
	position: relative;
	height: 48px;

	&--invalid .textInput,
	&--invalid .textInput__icon,
	&--invalid .textInput::placeholder {
		color: rgb(230, 50, 0) !important;
	}
	&:before,
	&:after {
		right: 100%;
		top: 25px;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}
}


.textInput__validationMessages {
	display: none;
    flex-direction: column;
	align-items: center;

	position: absolute;
	top: 0;
	right: -247px;
	width: 200px;
	min-height: 28px;
	padding: 10px;
	padding-left: 27px;

	z-index: 1;
	color: #999999;
	border: 1px solid #DDDDDD;
	background-color: #FFFFFF;
	margin: 0;
	border-radius: 5px;
	&:after {
		border-color: rgba(255, 255, 255, 0);
		border-right-color: #ffffff;
		border-width: 7px;
		margin-top: -7px;
	}
	&:before {
		border-color: rgba(0, 0, 0, 0);
		border-right-color: #DDDDDD;
		border-width: 8px;
		margin-top: -8px;
	}
}

.textInputWrapper--showErrors .textInput__validationMessages {
	display: flex;
}


.textInput__validationMessage {
	margin: 10px 0;
}

.textInput__validationIcon {
	font-size: 20px;
	margin: 3px 0;
}

.textInput__icon {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	height: 48px;
	width: 49px;
	font-size: 20px;
	color: #BBB;
	z-index: 1;
	&i:after {
		content: '';
	}
}