@import '../../../src/styles/media';

.help__page {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100vh;

	.page-content__wrapper {
		display: flex;
		justify-content: center;
	}
	.page-content {
		height: auto;
		min-width: 60rem;
		@media ($tablet) {
			min-width: 30rem;
		}
		max-width: 130rem;
		margin: 4rem;
		h1 {
			margin-top: 28px;
			margin-bottom: 10px;
			&.noTopMargin {
				margin-top: 0;
			}
		}
		h2 {
			//background-color: rgba($color: #5aa, $alpha: 0.3);
			margin-bottom: 8px;
		}
		.user_menu__logo--box {
			display: inline;
		}
		.user_menu__logo {
			height: 16px;
			width: 16px;
			fill: #AAA;
			margin-left: 8px;
			margin-right: 0;
		}
		p i.fa {
			color: #999999;
			padding-left: 4px;
		}
		p.hints {
			color: #AAA;
			font-style: italic;
			i.fa {
				color: #AAA;
			}
		}
		p.noTopMargin {
			margin-top: 0px;
		}
		p.noBottomMargin {
			margin-bottom: 0;
		}
		div.page-content__revealPanel {
			padding: 25px 15px 15px 26px;
			border-radius: 8px;
			background-color: rgba($color: #000, $alpha: 0.08);
		}

	}
	.subtleHighlight {
		color: #AAAAAA;
		font-style: italic;
	}


	.headingControl {
		display: flex;
		flex-direction: row;
	}

	.revealControl {
		//background-color: rgba($color: #55A, $alpha: 0.3);
		color: #AAAAAA;
		margin: 25px 0px 8px 10px;
		cursor: pointer;
	}

}
