@import '../../../src/styles/zIndex';

.share {
	position: absolute;
	top: 1rem;
	right: 1.3rem;

	z-index: 200;

	font-size: 2rem;
	color: #ccc;

	.backdrop {
		background-color: transparent;
	}

	.fa-share-alt {
		cursor: pointer;
		margin-right: 0.5rem;
	}

	.fa-files-o {
		margin-right: 0.5rem;
	}

	.fa-facebook {
		margin-right: 1rem;
	}
}

.share-panel {
	position: absolute;
	top: 3.2rem;
	right: -1rem;
	z-index: 200;
	background-color: #FFFFFF;
	padding: 0 1rem;
	border: 0.1rem solid #ddd;
	width: 32rem;

	box-shadow: -2px 5px 30px rgba(0, 0, 0, .3);
	text-shadow: none;

	&:after, &:before {
		bottom: 100%;
		left: 29.5rem;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}

	&:after {
		border-color: rgba(255, 255, 255, 0);
		border-bottom-color: #fff;
		border-width: 10px;
		margin-left: -10px;
	}
	&:before {
		border-color: rgba(102, 102, 102, 0);
		border-bottom-color: #ddd;
		border-width: 11px;
		margin-left: -11px;
	}

	// TODO: Add share--left and share--right
	.share--bottom > & {
		top: unset;
		right: unset;
		bottom: 5rem;
		left: -1rem;

		&:after, &:before {
			left: 2rem;
			bottom: unset;
			top: 100%;
		}

		&:after {
			border-bottom-color: transparent;
			border-top-color: #fff;
		}

		&:before {
			border-bottom-color: transparent;
			border-top-color: #fff;
		}
	}
	.share--bottomCentre > & {
		right: -14rem;
		&:after, &:before {
			left: 16.5rem;
		}
	}
}

.share-panel__button {
	height: 4rem;
	width: 14rem;
	padding: 0;
	flex-shrink: 0;

	& > i.fa {
		color: #FFF;
	}
}

.share-panel__line {
	display: flex;
	justify-content: space-between;
	border-bottom: 0.1rem solid #EEE;
	padding: 1rem 0;
	align-items: center;
	color: #000;
	font-size: 1.5rem;

	&:last-child {
		border-bottom: none;
	}
}

.share-panel__title {
	color: #000;
	font-size: 1.6rem;
	padding: 1rem 0;
}

.share-panel__copy {
	border: none;
	padding: 0.2rem;
	height: 2rem;
	width: 15rem;
}
