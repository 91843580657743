@import '../../../../src/styles/zIndex';

.formImage__wrapper {
	display: flex;
	justify-content: center;
	align-items: center;

	width: 364px;
	height: 273px;
	overflow: hidden;
	position: relative;
}

.formImage {
	width: 364px;

	&.formImage--rotated {
		width: auto;
		height: 364px;
	}

	&--portrait {
		margin-top: 25%;
	}

	&--push-to-height {
		height: 273px;
		width: auto;
		flex: 0 0 auto;

		&.formImage--rotated {
			width: 273px;
			height: auto;
		}
	}

	&--push-to-width {
		width: 364px;

		&.formImage--rotated {
			width: auto;
			height: 364px;
		}
	}

	&--empty {
		display: flex;
		justify-content: center;
		align-items: center;

		width: 100%;
		height: 100%;

		font-size: 12.8rem;
		color: #DDD;
	}

	&--loading {
		display: none;
	}
}

.formImage__spinner {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.formImage__hover {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	width: 100%;
	height: 273px;
	color: #666;
	background-color: #f5f5f5;

	position: absolute;
	opacity: 0;
	z-index: 1;
}

.formImage__wrapper:hover {
	.formImage__hover {
		cursor: pointer;
		opacity: 0.9;
	}

	.formImage--editable.formImage--empty {
		display: none;
	}
}

.formImage__icon {
	color: #666;
	font-size: 128px;
}

.formImage__input {
	visibility: hidden;
	height: 0;
}

.formImage__error-wrap {
	position: absolute;
	bottom: 2rem;
	width: 100%;
	display: flex;
	justify-content: center;
	z-index: z-index(foreground);
}

.formImage__error {
	padding: 1rem;
	border-radius: 0.5rem;
	background-color: #FFFFFF;
	color: #e63200;
	border: 0.1rem solid #666;
	padding: 1rem;
	width: 30rem;
	display: flex;
	justify-content: center;
}
