.products-wrapper {
	display: flex;
	flex-direction: column;
	background-color: #F7F7F7;
	width: 100%;
	//height: calc(100vh - 6.1rem);
}

.products {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	flex: 1 0 auto;
    min-height: calc(100vh - 431px);  // 431 = heaser plus footer heights.
}