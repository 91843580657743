$z-index: (
	background: -100,
	behind:     -50,
	default:     0,
	forward:     50,
	foreground:  75,
	modal:       100,
);

@function z-index($key) {
	@return map-get($z-index, $key);
}
