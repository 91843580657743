.modal.clear-cart {
	display: flex;
	flex-direction: column;

	padding: 3rem;

	height: 20rem;
}

.clear-cart__view {
	display: flex;
	height: 10rem;
}

.clear-cart__buttons {
	display: flex;
	justify-content: flex-end;
}

.clear-cart__button {
	color: #FFFFFF;
	background-color: #CC0000;
	margin-left: 1.5rem;
}

.clear-cart__title {
	color: #CC0000;
	font-size: 2.4rem;
	margin-bottom: 1rem;
}

.clear-cart__title i {
	margin-right: 1rem;
}