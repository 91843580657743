@import '../../../src/styles/media';

.about__page {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100vh;

	.page-content__wrapper {
		display: flex;
		justify-content: center;
	}
	.page-content {
		display: flex;
		justify-content: space-between;
		flex: 1 1 auto;
		flex-direction: row;
		@media ($tablet) {
			flex-direction: column;
		}
		height: auto;
		min-width: 30.7rem;
		max-width: 120rem;
		margin: 4rem;
		//background-color: rgba($color: rgb(255, 153, 0), $alpha: 0.1);
		.page-column {
			width: 32%;
			@media ($tablet) {
				width: 100%;
			}
			h2 {
				margin-top: 16px;
				margin-bottom: 4px;
			}
			h3 {
				margin-top: 12px;
				margin-bottom: 2px;
			}
			P {
				margin-top: 7px;
				margin-bottom: 21px;
				color: #555;
				font-size: 1.5rem;
				line-height: 1.9rem;
				a, a:visited {
					color: #00baff;
					text-decoration: none;
					&:hover {
						text-decoration: underline;
					}
				}
			}
			.subtitle {
				font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
				color: #00BAFF;
				font-size: 1.5rem;
				font-style: italic;
				margin-top: 0;
				margin-bottom: 0;
			}
			.highlight {
				font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
				font-size: 2.2rem;
				//margin-right: 20px;
				margin-bottom: 10px;
				font-weight: 100;
				font-style: italic;
				line-height: 2.6rem;
				//text-align: center;
				color: #AAA;
				@media ($tablet) {
					color: #888;
					font-size: 2.5rem;
					line-height: 2.9rem;
				}
			}
			&--Image {
				width: 100%;

			}
			//background-color: rgba($color: rgb(0, 162, 255), $alpha: 0.1);
		}
	}
	.subtleHighlight {
		color: #AAAAAA;
		font-style: italic;
	}

}



