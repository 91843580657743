@import '../../../src/styles/zIndex';
@import '../../../src/styles/media';

.modal {
	width: 65rem;
	height: 28.6rem;
	margin-top: 15rem;
	background-color: #FFF;

	@media ($tablet) {
		height: 40rem;
	}
}
