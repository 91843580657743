@import '../../../src/styles/colours';

.recordBox {
	width: 364px;
	min-width: 364px;
	height: 350px;
	background-color:#AAA;
	margin: 20px;
	padding:0;
	/* border: 1px solid #00BAFF; */
	border-radius: 6px;
	background-color:#FFF;
	background-size: contain;
	text-decoration: none;
}

.recordBox--new {
	min-width: 284px;
	padding: 40px;
	padding-bottom: 20px;
	background-color:#00BAFF;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	font-size: 16pt;
	color: #FFF;

	.jbc-theme & {
		background-color: $jbc-primary-colour;
	}
	.gla-theme & {
		background-color: $gla-primary-colour;
	}
}

.recordBox__frame--top {
	width: 364px;
	height: 273px;
	border-radius: 6px 6px 0 0;
    overflow: hidden;
}
.recordBox__image {
	max-width: 364px;
}

.recordBox__frame--base {
	width:364px;
	border-radius: 0 0 6px 6px;
}

.recordBox__fullName {
	font-size: 16pt;
	color:#555;
	padding: 10px 10px;
}

.recordBox__years {
	font-size: 10pt;
	color:#999;
	padding: 0px 10px;
}