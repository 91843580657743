@import '../../../src/styles/zIndex';

.signin-panel {
	position: absolute;

	width: 30rem;
	padding: 1.5rem;
	bottom: -10rem;

	background-color: #FFFFFF;
	box-shadow: -2px 5px 30px rgba(0,0,0,.3);

	z-index: z-index(modal);

	&:after, &:before {
		bottom: 100%;
		left: 50%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}

	&:after {
		border-color: rgba(255, 255, 255, 0);
		border-bottom-color: #fff;
		border-width: 10px;
		margin-left: -10px;
	}
	&:before {
		border-color: rgba(102, 102, 102, 0);
		border-bottom-color: #ddd;
		border-width: 11px;
		margin-left: -11px;
	}
}

.signin-panel__instruction {
	text-align: center;
	margin-bottom: 2rem;
}

.signin-panel__buttons {
	display: flex;
	justify-content: space-around;
}

.signin-panel__backdrop {
	left: 0;
	z-index: z-index(foreground);
	background-color: transparent;
}
