//@import '../../../src/styles/zIndex';
@import '../../../src/styles/media';

.pageMenu--account {
	margin-left: 100px;
	@media ($tablet) {
		margin-left: 0px;

	}
}

