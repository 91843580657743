.product {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;
	background-color: #FFFFFF;
	border: 1px solid #00BAFF;
	width: 28.2rem;
	height: 57rem;
	margin: 4rem 1rem;
}

.product__name {
	display: flex;
	justify-content: center;
	align-items: center;

	height: 6rem;
	color: #FFFFFF;
	font-size: 2.4rem;
	background-color: #02A9E3;
}

.product__price {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-grow: 1;

	height: 11rem;
	color: #FFFFFF;
	font-size: 5rem;
	background-color: #00BAFF;
	&--timePeriod {
		color: #bdeafc;
		font-size: 1.8rem;
	}
}

.product__features {
	display: flex;
	flex-direction: column;

	height: 18.4rem;
	padding: 0 2rem;
}

.feature {
	display: flex;
	justify-content: center;
	align-items: center;

	flex: 1;
	color: #999999;
	border-bottom: 1px solid #EEEEEE;
}

.product__buttons {
	display: flex;
	justify-content: center;
	align-items: center;

	height: 10rem;
}

.product__button {
	width: 21rem;
	white-space: nowrap;
	.button__label {
		display: flex;
		justify-content: space-between;
		flex: 1 1 auto;
		flex-direction: row;
	}
}

.product__sub {
	&--green {
		color: #b5dfa2;
		font-size: 1.2rem;
	}
	&--blue {
		color: #a6e0f7;
		font-size: 1.2rem;
	}
	/*
green (6aa84f)
darker green (38761d)
light green (93c47d)
light blue (b5e9fd)
	*/
}

.product-ribbon {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;

	height: 10rem;
	margin-top: 1rem;

	font-size: 1.6rem;
	color: #FFF;

	&--blue {
		background-color: #00BAFF;

		.product-ribbon__description {
			line-height: 2.2rem;
			width: 25rem;
		}
		&:before {
			border-color: transparent transparent #00BAFF transparent;
		}
	}

	&--green {
		background-color: #6aa84f;

		&:before {
			border-color: transparent transparent #6aa84f transparent;
		}
	}

	&:before {
		content: ' ';
		position: absolute;
		top: -2rem;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 14rem 2rem 14rem;
	}
}

.product-ribbon__button {
	width: 21rem;
	white-space: nowrap;
	margin: 0.7rem;
	.button__label {
		display: flex;
		justify-content: space-between;
		flex: 1 1 auto;
		flex-direction: row;
	}
}

.product-ribbon__top {
	position: absolute;
	top: -0.5rem;
}