.select-wrapper {
	position: relative;
	display: flex;
	flex-direction: column;
	background-color: none;
	&:after {
		content: "▼";
		padding: 12px 20px 12px 0;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 1;
		text-align: center;
		width: 40px;
		height: 100%;
		pointer-events: none;
		color:#DDD;
	}
}

.select {
	height: 4.8rem;
	border: none;
	color: #BBB;
	font-size: 12pt;
	padding-left: 4.4rem;

	-webkit-appearance:none;
	&::-ms-expand {
		display: none;
	}
	-moz-appearance: none;

	background-color: #FFFFFF;
	border: 1px solid #DDDDDD;
	border-radius: 5px;
	option {
		font-size: 10pt;
	}
}

.select__icon {
	position: absolute;
	width: 4.8rem;
	height: 100%;
	font-size: 2rem;
	display: flex;
	justify-content: center;
	align-items: center;

	.fa {
		color: #BBB;
		border: none;
	}
}

.select:focus {
	border-color: rgba(0, 186, 255, 0.8);
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(0, 186, 255, 0.6);
	outline: 0 none;
	border:solid 1px #00BAFF;
}

.select__error {
	min-width: 170px;
	color: rgb(230, 50, 0);
	margin-top: 5px;
}
option:disabled {
	color: 	#CCCCCC;
}