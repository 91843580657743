@import '../../../src/styles/media';
@import '../../../src/styles/zIndex';

.search {
	position: relative;
	display: flex;
	align-content: center;
	width: 410px;
}

.search__button {
	border: none;
	background:none;
	padding-left:10px;
	color:#BBBBBB;
	font-size:12pt;
	outline: none;
}

/* V2.0 TURN OFF (to white) blue glow around focused input boxes RMN*/
.search__input {
	height: 38px;
	width: 320px;
	outline: 0 none;
}

.search__input .textInput {
	font-size: 16px;
}

@media ($desktop-m) {
	.search {
		width: 310px;
	}
	.search__input .textInput {
		width: 240px;
	}
}

@media ($desktop-s) {
	.search {
		width: 210px;
	}
	.search__input .textInput {
		width: 175px;
	}
}

// @media ($tablet) {
// 	.search {
// 		display: none;
// 	}
// }

.search__results {
	position: absolute;
	top: 4rem;
	border: 1px solid #DDDDDD;
	left: 0;
	width: 39rem;
	background-color: #FFFFFF;
	border-radius: 0.5rem;
	z-index: z-index(foreground);
	display: flex;
	flex-direction: column;

	&--empty {
		align-items: center;
		padding: 1rem;
		color: #999;
	}
}

.search__result {
	display: flex;
	align-items: center;
	height: 5rem;
	padding-left: 1rem;
	color: #999;
	cursor: pointer;
	border-bottom: 1px solid #DDDDDD;
	text-decoration: none;

	&:last-child {
		border-bottom: none;
	}

	&:hover {
		background-color: #EEEEEE;
	}
}

.search__image {
	width: 4rem;
	height: 4rem;

	& .formImage--push-to-height {
		height: 4rem;
	}

	& .formImage--push-to-width {
		width: 4rem;
	}
}

.search__title {
	padding: 0 1rem;
	color: #555555;
}

.search__moment-count {
	margin-left: 1rem;
}

.search__moment-count,
.search__timespan {
	font-size: 1.2rem;
	color: #999999;
}

.search__spinner {
	width: 4rem;
}
