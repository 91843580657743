@import '../../../src/styles/zIndex';
@import '../../../src/styles/media';

.full-moment__canvas {
	position: fixed;
	top:0;
	left:0;
	width:100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color:rgba(0, 0, 0, .7);
	z-index: z-index(modal);
	@media ($tablet) {
		// TASK: FOR DEVICES WITH URL BAR CHROME (where the appearance or disappearance of the URL and other nav bars (should but dont) change the vh)...
		// UPDATE: Seems to be solved by manually pussing the Full-Moment box to viewport size using JS
		// behold the joy of URL Bar Resizing
		// https://developers.google.com/web/updates/2016/12/url-bar-resizing
		background-color: rgba(0, 0, 0, .8);
	}
}

.full-moment {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0px;
	width: 80vw;
	height: 80vh;
	flex: 0 0 auto;
	z-index: z-index(foreground);
	position: relative;

	&--landscape {
		flex-direction: column;
	}
}


.full-moment__image {
	display: flex;
	justify-content: center;
	align-items: center;

	max-width: 100%;
	max-height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	.formImage {
		text-align: right;
	}
	.formImage__hover {
		background-color: none;
		color:#FFF;
	}
	@media ($tablet) {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	&--loaded{					// hides the occasional pixel gap between image and info panel due to rounding of image pixel dimensions
		background-color: #FFFFFF;
	}
	&--small {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.formImage__hover {
		width: 100%;
		height: 100%;
		font-size: 24px;
		background-color: rgba(0, 0, 0, 0.3);
		text-shadow: 2px 2px 6px #000000;
	}
}

.full-moment__image>img.formImage--push-to-width,
.full-moment__image>img.formImage--push-to-height {
	width:auto;
	height:auto;
	transform: none;
	margin:0;
}
.full-moment__image>img.formImage--portrait {
	margin:0;
}
.full-moment__image:hover .formImage__icon,
.full-moment__image .formImage__icon {
	color:#FFF;

}

//TESTING !!!
.formImage .fa {
	@media ($mobile-l) {
		font-size: 40px;
	}
}

.full-moment__card {
	flex-grow: 0;
	flex-shrink: 0;
	width: 364px;
	padding: 12px;
	background-color:#FFFFFF;
	@media ($tablet) {
		padding: 10px;
		height: 100% !important;
		//width: 200px !important;  // TASK: adjust smaller to reduce portrait card on mobile devices (and allow larger image).
									// Will need to review image size calculations too (use landscape check to do so). Careful of circular logic
	}
	@media ($mobile-l) {
		padding: 3px;
	}
	&--landscape {
		flex-direction: row;
		@media ($tablet) {
			height: 150px !important;
			width: 100% !important;
		}
		.full-moment__dateCircaIconsAndDescription {
			flex: 1 1 auto;										// landscape: grow width of description
		}
		.full-moment__feed {
			flex: 0 0 40rem;									// landscape: fixed comments width
		}
		.full-moment__dateCircaAndIcons	{
			flex-direction: row;
			justify-content: space-between;
			.full-moment__icons {
				text-align: right;
			}
		}
	}
}


// .full-moment__card											{	background-color: rgba(255, 255, 255, 0.9);	}
// .full-moment__card--landscape								{	background-color: rgba(255, 255, 255, 0.9);	}
// .full-moment__section										{	background-color: rgba(50, 100, 255, 0.1);	}
// .section__datetime											{	background-color: rgba(255, 50, 204, 0.1);	}
// .full-moment__section--datetime								{	background-color: rgba(50, 255, 50, 0.1);	}
// .full-moment__datetime--circa								{	background-color: rgba(255, 50, 50, 0.1);	}
// .full-moment__shortDescription								{	background-color: rgba(252, 255, 50, 0.1);	}
// //.full-moment__footer										{	background-color: rgba(255, 136, 0, 0.1);	}
// .full-moment__metaData										{	background-color: rgba(50, 100, 255, 0.1);	}
// .button-box													{	background-color: rgba(50, 100, 255, 0.1);	}
// .full-moment__form-controls									{	background-color: rgba(50, 100, 255, 0.1);	}
// .full-moment__section--feed									{	background-color: rgba(255, 0, 212, 0.1);	}

// .full-moment__card											{	background-color: rgb(0, 255, 191);	}
// .full-moment__card--landscape								{	background-color: rgb(255, 137, 2);	}
// .full-moment__dateCircaIconsAndDescription		{	padding:5px; background-color: rgba(255, 5, 243, 0.6);	}
// .full-moment__dateCircaAndIcons					{	padding:5px; background-color: rgba(5, 72, 255, 0.5);	}
// .full-moment__dateCircaAndIcons					{	padding:0px; background-color: rgba(255, 255, 255, 1);	}
// .full-moment__dateAndCirca						{	padding:5px; background-color: rgba(230, 255, 6, 0.3);	}
// .full-moment__icons								{	padding:5px; background-color: rgba(9, 255, 62, 0.3);	}
// .full-moment__description						{	padding:5px; background-color: rgba(130, 0, 252, 0.3);	}
// .full-moment__feed								{	padding:5px; background-color: rgba(172, 92, 2, 0.3);	}
// .full-moment__date								{	padding:5px; background-color: rgba(2, 172, 79, 0.3);	}
// .full-moment__circa								{	padding:5px; background-color: rgba(255, 6, 143, 0.3);	}
// .full-moment__circa								{	padding:5px; background-color: rgba(255, 6, 143, 0.3);	}
// .full-moment__circa								{	padding:5px; background-color: rgba(255, 6, 143, 0.3);	}


.full-moment__dateCircaIconsAndDescription {
	display: flex;
	flex-direction: column;
	flex: 0 0 25rem; 										// default context is portrait panel ►Check description might be enough to size, return this to auto
	width: 100%;	// IE11
}
.full-moment__dateCircaAndIcons	{
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width:100%;
	&--switched	{											// Used when turning on edit mode to place the icon box (which holds the form controls) above the dates.
		flex-direction: column-reverse;
	}
}
.full-moment__dateAndCirca {
	flex: 0 0 auto;
	display: flex;
	flex-direction: row;
}
.full-moment__icons {
	padding-top: 12px;
	padding-bottom: 5px;
	flex: 1 0 auto;
	font-size: 1.8rem;
	.fa {
		color:#CCC;
		padding:0 5px;
		margin-right: 1rem;
		@media ($mobile-l) {
			font-size: 1.6rem;
			margin-right: 0.1rem;
		}
	}
	.fa:hover {
		color:#00BAFF;
		cursor: pointer;
	}
	.fa.full-moment__delete:hover  {
		color: rgb(255, 60, 0);
	}
	.fa.full-moment__save:hover {
		color: #6aa84f;
	}
	&--author {
		display: inline-block;
	}
	@media($mobile-l) {
		padding-top: 4px;
		padding-bottom: 2px;
	}
}

.full-moment__description {
	flex: 1 1 auto;
}
.full-moment__feed {
	flex: 1 0 auto; 										// default context is portrait panel
	padding:5px;
	color:#CCC;
	border-left: solid 1px #EEE;
}


.full-moment__card--landscape .full-moment__dateAndIcons {
	flex: 1 0 210px;
	@media ($tablet) {
		flex: 1 0 50px;
		margin-top: 0;
	}
}
.full-moment__card--landscape .full-moment__icons {
	padding-top: 0;
	padding-bottom: 0;
}
.full-moment__card--landscape .full-moment__description {
	flex: 1 1 auto;
	.input[type="textarea"] {
		font-size: 1.4rem;
		height: 65px;
	}
}
.full-moment__card--landscape .full-moment__controls {
	flex: 1 1 250px;
	align-self: flex-end;
}


.full-moment__description.full-moment__display-field {
	white-space: pre-line;
}



.full-moment__display-field {
	padding: 8px 3px;
	width:100%;
}
.full-moment__form-field,
.full-moment__form-field textarea,
.full-moment__form-field input {
	//font-size:16px;
	color:#666;
	font-family:Arial, Helvetica, sans-serif;
	margin:0;
	padding:5px;
}
.full-moment__form-field {
	width:100%;
}
.full-moment__form-field textarea,
.full-moment__form-field input {
	width:100%;
	border: solid 1px #D5D5D5;
}
.full-moment__form-field textarea::placeholder,
.full-moment__form-field input::placeholder {
	color:#CCC;
}
.full-moment__year {
	width:74px;
}
.full-moment__month,
.full-moment__day {
	width:50px;
}
.full-moment__year input {
	width: 62px;
}
.full-moment__month input,
.full-moment__day input{
	width: 39px;
}


.section__datetimeAndCirca {
	display: flex;
	flex-direction: row;
	justify-content: left;
	white-space: nowrap;
}
.full-moment__section--datetime { // Just the date and time - not the circa
	// padding: 11px;
	color:#00BAFF;
	font-size:1.8rem;
	@media ($mobile-l) {
		font-size:1.6rem;
	}
}
.full-moment__section--date-parts { // for the form inpout so fall date parts and the approximate checkbox
	display: flex;
	flex-direction: row;
	justify-content: left;
}
.full-moment__datetime--circa {
	padding: 0 8px;
	color:#CCC;
	font-size:1.4rem;
}

.full-moment__section--datetimePartsInputs {
	display: flex;
	flex-direction: row;
}


.full-moment__approximate { 				// form
	flex-direction: row-reverse;
	width:auto;
	padding-top:22px;
	padding-bottom:0;
	justify-content: flex-end;
}
.full-moment__approximate input[type="checkbox"] {
	width: 15px;
	height: 15px;
	margin: 5px;
	background: #fcfff4;
	background: linear-gradient(to bottom, #fcfff4 0%, #dfe5d7 40%);
	cursor:pointer;
}
.full-moment__approximate label {
	padding-top:5px;
	cursor:pointer;
	@media ($mobile-l) {
		font-size:1.1rem;
	}
}




.full-moment__form-controls {
	margin-top: 0;					// was 20
	padding-left: 5px;
	justify-content: flex-start;
	align-self: flex-end;
}
.full-moment__button {
	width: auto;
	margin-right: 6px;
    padding: 13px 45px;
	@media ($mobile-l) {
		padding: 6px 25px;
		font-size: 9pt;
	}
}
.full-moment__button--small {
	padding: 6px 25px;
	font-size: 9pt;
	@media ($mobile-l) {
		padding: 6px 10px;
	}
}



.full-moment__button--blue {
	color:#00BAFF;
}

.full-moment__privacy--buttons {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}
.full-moment__privacy .full-moment__privacy-button {
	width:210px;
}
.full-moment__privacy .full-moment__privacy-button .fa {
	padding-right:10px;
	color:#00baff;
	font-size:24px;
}
.full-moment__privacy .full-moment__privacy-button--go .fa {
	padding-right:10px;
	color:#FFFFFF;
	font-size:24px;
}
.full-moment__privacy .full-moment__privacy-button--go {
	background-color:#CCC;
	width:100px;
}



.rounded-arrow {
	position: absolute;
	top: calc(50% - 100px);
	width: 75px;
	height: 200px;
	padding-top: 50px;
	overflow: hidden;
	z-index: 1000;
	cursor: pointer;
	opacity: 0.2;
	transition: opacity 0.2s ease-out;

	@media ($tablet) {
		width: 50px;
		height: 100px;
		padding-top: 0px;
		opacity: 1; 				// move transparency control to the element for mobile devices without hover
	}
	&:hover {
		display: block;
		opacity: 1;
	}
	&--left {
		right: unset;
		left: 0;
		transition-property: opacity, left;
		&.rounded-arrow--closed {
			left: -7.5rem;
		}
		.rounded-arrow__circle {
			left: -50px;
			font-size: 35px;
			@media ($tablet) {
				background-color: rgba(0, 0, 0, 0.15);
			}
		}
		.rounded-arrow__chevron {
			position: absolute;
			top: 33px;
			right: 20px;
			text-shadow: 1px 2px 1px black;
			color: white;
			@media ($tablet) {
				color: rgba(255, 255, 255, .9);
				// width: 50px;
				// height: 100px;
				// padding-top: 0px;
			}
		}
	}
	&--right {
		left: unset;
		right: 0;
		transition-property: opacity, right;
		&.rounded-arrow--closed {
			right: -7.5rem;
		}
		.rounded-arrow__circle {
			right: -50px;
			font-size: 35px;
			@media ($tablet) {
				background-color: rgba(0, 0, 0, 0.15);
			}
		}
		.rounded-arrow__chevron {
			position: absolute;
			top: 33px;
			left: 20px;
			text-shadow: 2px 1px 1px black;
			color: white;
			@media ($tablet) {
				color: rgba(255, 255, 255, .9);
				// width: 50px;
				// height: 100px;
				// padding-top: 0px;
			}
		}
	}
	&__circle {
		position: absolute;
		width: 100px;
		height: 100px;
		border-radius: 50px;
		background-color: rgba(0, 0, 0, 0.2);
	}
}

.full-moment-controls {
	display: flex;
	justify-content: flex-end;
	align-items: center;

	position: absolute;
	top: 0;
	right: 0;
	width: 6rem;
	height: 3rem;
	margin: 1rem;
	z-index: z-index(modal);
	transition: top 0.2s ease-out;

	&--closed {
		top: -4rem;
	}

	.full-moment-controls__close, .full-moment-controls__zoom {
		display: flex;
		justify-content: center;
		align-items: center;

		width: 3rem;
		height: 3rem;
		font-size: 2.5rem;
		color: white;
		text-shadow: 1px 2px 1px black;
		cursor: pointer;
		background: radial-gradient(circle, rgba(0, 0, 0, 0.1) 1%, transparent 80%);

		@media ($tablet) {
			opacity: 0.8;
		}
	}

	.full-moment-controls__zoom {
		margin-top: 0.3rem;
		font-size: 1.9rem;
	}
}
// remove positioning. Handled differently in places. See AP-214
.full-moment__share {
	display: inline-block;
	position: relative;
	top: unset;
	right: unset;
	font-size: unset;
}
