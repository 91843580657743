
@import '../../../src/styles/media';
@import '../../../src/styles/zIndex';


.dateMenu {
    height: calc(100vh - 62px);
	background-color: #F5F5F5;

	/* spreads dates out to screen height */
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;

	z-index: z-index(foreground);

	width: 100px;
	flex-grow: 0;
	flex-shrink: 0;
	position: fixed;  /* added to support older browsers */
	//position: sticky;
	left:364px;
	font-size:11pt;
	@media ($tablet) {
		flex-direction: row;
		justify-content: center;
		align-content: flex-start;
		flex-wrap: wrap;
		height: auto;
		position: relative;
		width:100%;
		left:0;
		padding:10px 50px;
		background-color: rgba(250, 250, 250, 0.95);
	}
}

.app:not(.app--signed-in) .dateMenu {
	@media ($mobile-s) {
		padding:10px 5px;
	}
}

.dateMenu__year {
	display: flex;
	flex-direction: column;
	color:#666;
	flex-grow: 1;
	width:100%;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	text-decoration: none;

	background-color: #EDEDED;
	transition: background-color 0.1s linear;
	@media ($tablet) {
		flex: 0 0 auto;
		width:50px;
		margin:5px;
		background-color: rgba(255, 255, 255, 1);
	}
	@media ($mobile-m) {
		width:40px;
		margin:1px;
	}
}
.dateMenu__year:hover {
	color:#00BAFF;
	background-color: transparent;
}
