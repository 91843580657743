@import '../../../src/styles/media';

.privacy-policy__page {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100vh;
	.compactSliderHeading {
		margin-bottom: .4rem;
	}
	.page-content__wrapper {
		display: flex;
		justify-content: center;
	}
	.page-content {
		display: flex;
		justify-content: space-between;
		flex: 1 1 auto;
		flex-direction: row;
		@media ($tablet) {
			flex-direction: column;
		}
		height: auto;
		min-width: 30.7rem;
		max-width: 120rem;
		margin: 4rem;
		//background-color: rgba($color: rgb(255, 153, 0), $alpha: 0.1);
		.page-colspanThree {
			width: 98%;
		}
		.page-column {
			width: 32%;
			@media ($tablet) {
				width: 100%;
			}
			.subtleHighlight {
				color: #AAAAAA;
				font-style: italic;
			}
		}
		&.smallTopMargin {
			margin-top:5px;
		}
		&.smallBottomMargin {
			margin-bottom:5px;
		}
	}
}