@import '../../../src/styles/zIndex';
@import '../../../src/styles/media';

$fixed-column-width: 8rem;
$fixed-column-width-small: 4rem;
$fixed-column-font-size: 1.4rem;
$fixed-column-font-size-small: 1.0rem;


.cart-wrapper {
	display: flex;
	justify-content: center;
	align-items: flex-start;

	background-color: #F0F0F0;
	width: 100%;
	height: calc(100vh - 6.1rem);
}

.cart {
	display: flex;
	flex-direction: column;
	margin-top: 4rem;
	width: 75rem;
	@media ($mobile-l) {
		margin-top: 2.8rem;
	}
}

.cart-table {
	display: flex;
	flex-direction: column;
	border-collapse: collapse;
	width: 100%;
	background-color: #FFFFFF;
	font-size: $fixed-column-font-size;
	@media ($mobile-l) {
		font-size: $fixed-column-font-size-small;
	}
}

.cart-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
	height: 4rem;
	background-color: #F0F0F0;
	@media ($mobile-l) {
		height: 3rem;
	}
}

.cart-title__item {
    display: flex;
    flex-direction: row;
    width: 100%;
	justify-content: flex-start;
	font-size: 2.4rem;
	&:first-child {
		margin-left: 1rem;
	}
	@media ($mobile-l) {
		font-size: 1.7rem;
	}
}

.cart-item__title {
	display: flex;
	flex: 1 1 auto;
	flex-direction: row;
	align-items: center;
	height: 6rem;
	margin-left: 1rem;
	@media ($mobile-l) {
		font-size: 1.2rem;
	}
}

.cart-item {
	display: flex;
	flex: 0 0 auto;
	flex-direction: row;
	height: 6rem;
	vertical-align: middle;
	border-top: 1px solid #F0F0F0;
	&:first-child {
		border-top: none;
	}
	.formImage--push-to-width {
		width: 5.9rem;
	}
	.formImage--push-to-height {
		height: 4.5rem;
	}
	&--totals {
		font-size: 2.4rem;
		height: 6rem;
		@media ($mobile-l) {
			font-size: 1.4rem;
			height: 4rem;
			& .cart-item__title { // we use an empty title row to maintain table column spacing
				height: 4rem;
			}
		}
	}

/* TESTING
	& div:nth-child(1) {
		background-color: rgba(0, 187, 255, 0.412);
	}
	& div:nth-child(2) {
		background-color: rgba(51, 255, 0, 0.369);
	}
	& div:nth-child(3) {
		background-color: rgba(255, 0, 200, 0.431);
	}
	& div:nth-child(4) {
		background-color: rgba(255, 208, 0, 0.533);
	}
	& div:nth-child(5) {
		background-color: rgba(255, 0, 106, 0.42);
	}
	& div:nth-child(6) {
		background-color: rgba(0, 134, 29, 0.365);
	}
	*/
}

.cart-item__col {
	flex: 0 1 auto;
	display: flex;
	vertical-align: middle;
	align-items: center;
	//border-right: 1px solid #FF00FF;
}

.cart-item__col--right {
    display: flex;
	flex: 0 0 auto;
	width: $fixed-column-width;
	text-align:right;
	justify-content: flex-end;
	align-content: right;
    padding-right: 10px;
	@media ($mobile-l) {
		padding-right:3px;
		width: $fixed-column-width-small;
	}
}

.cart-item__title--iconBox {
	flex: 0 0 auto;				// makes div contain image on iOS
	display: block;				// important, contains hidden image overflow (stops div expanding to contain it even though it's not visible)
	& .fa {
		font-size:4rem;
		width:5.9rem;
		height:4.4rem;
		text-align: center;
		color: #CCC;
		margin-right: 1rem;
		@media ($mobile-l) {
			width:2.9rem;
			height:4.0rem;
			font-size:3rem;
			padding-top:4px;
		}
	}
}

.cart-item__image {
	display: inline-block;
	width: 5.9rem;
	height: 4.4rem;
	border: 0.1rem solid #AAA;
	margin-right: 1rem;
}



.cart-buttons {
	display: flex;
	justify-content: space-between;
	width: 100%;
}
.cart-buttons__right {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	flex: 1 1 auto;
	// & .cart-buttons__button:last-child {
	// 	margin-left: 1rem;
	// }
}

.cart-buttons__button {
	margin: 1rem;
	&--last {
		margin-right: 0;
	}
	&--first {
		margin-left: 0;
	}
	@media ($mobile-l) {
		margin: 0.55rem;
		font-size: 1.3rem;
		padding:10px 20px;
		&--last {
			margin-right: 0.5rem;
		}
		&--first {
			margin-left: 0.5rem;
		}
	}
}

.cart-buttons__icon {
	margin-right: 0.5rem;
}



.cart-item__remove {
	flex: 0 0 auto;
	color: #CC0000;
	cursor: pointer;
	width: $fixed-column-width;
	text-align: center;
	@media ($mobile-l) {
		width: $fixed-column-width-small;
	}
	& .fa {
		font-size:2.5rem;
		width:4rem;
		height:3.8rem;
		padding-top: 6px;
		text-align: center;
		margin-right: 1rem;
	}
}

.description {
	position: relative;
	color: #00baff;
	cursor: pointer;
	width: 8rem;
}

.description__popup {
	position: absolute;
	width: 30rem;
	z-index: z-index(foreground);
	background-color: #FFFFFF;
	box-shadow: 0 3px 15px rgba(0,0,0,.1);
	padding: 3rem;
	margin-top: 0.5rem;
	color: #333;

	display: none;

	&--open {
		display: block;
	}
}

.description__backdrop {
	left: 0;
	z-index: z-index(foreground);
	background-color: transparent;
}

.description__close {
	position: absolute;
	bottom: 0;
	right: 0;
	padding: 0.5rem;
	font-size: 1.2rem;
	color: #999;
}

.option {
	display: flex;
	flex-direction: column;
	padding: 1rem;
}

.option__button {
	height: 2.3rem;
	width: 5.3rem;
	color: #EEEEEE;
	padding: 0;
	align-self: flex-end;
	margin-top: 1rem;
	margin-right: 0.5rem;
	margin-bottom: 3rem;
	font-size: 1.3rem;
}

.choice {
	position: relative;
	display: flex;
	align-items: center;
	height: 6rem;
	padding: 1rem;
	border-bottom: 0.1rem solid #F0F0F0;
	font-size: 1.6rem;
	cursor: pointer;
	&:hover {
		background-color: rgba(0, 153, 255, 0.05);
	}
	&--inactive * {
		opacity: 0.4;
		cursor: default;
	}

	&:first-child {
		border-top: 1px solid #F0F0F0;
	}

	.formImage--push-to-width {
		width: 6rem;
	}

	.formImage--push-to-height {
		height: 4.5rem;
	}

	.formImage--empty {
		font-size: 5rem;
	}
}

.choice__icon {
	width: 6rem;
	height: 4.5rem;
	margin-right: 1rem;
	@media ($mobile-l) {
		& .fa {
			font-size:4rem;
			padding-top:4px;
		}
	}
}

.choice__image {
	display: inline-block;
	width: 6rem;
	height: 4.5rem;
}

.choice__text {
	flex: 1;
	font-size: $fixed-column-font-size;
	@media ($mobile-l) {
		font-size: $fixed-column-font-size-small;
	}
}

.choice__tick {
	font-size: 3rem;
	@media ($mobile-l) {
		font-size: 2.4rem;
	}
	color: #DDDDDD;
	cursor: pointer;
	margin-right: 1.1rem;

	&:hover {
		color: #999999;
	}

	.choice--active & {
		color: #00BAFF;
	}

	.choice--inactive & {
		cursor: default;

		&:hover {
			color: #DDDDDD;
		}
	}
}

.choice__new {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 3rem;
	color: #CCC;
	height: 100%;
	width: 100%;
}

.cart-item__options-toggle {
	color: #00BAFF;
	cursor: pointer;

	&--open {
		color: #BBBBBB;
	}
}

.choice__inactive-tooltip {
	display: none;

	.choice--inactive:hover & {
		display: block;
		border: 1px solid #dddddd;
		position: absolute;
		opacity: 1;
		width: 20rem;
		padding: 1rem;
		font-size: 1.2rem;
		background-color: #FFFFFF;
		right: 1rem;
		color: #333333;

		// Tooltip pointer
		&:after, &:before {
			right: 100%;
			top: 50%;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
		}
		&:after {
			border-color: rgba(255, 255, 255, 0);
			border-right-color: #ffffff;
			border-width: 10px;
			margin-top: -10px;
		}
		&:before {
			border-color: rgba(221, 221, 221, 0);
			border-right-color: #dddddd;
			border-width: 11px;
			margin-top: -11px;
		}
	}
}

.cart-invoice {
	display: flex;
	flex-direction: column;
	padding: 2rem;
	background-color: #FFFFFF;
	border-top: 0.1rem solid #F0F0F0;
}

.cart-invoice__title {
	color: #38761D;
	font-size: 2.4rem;
	margin-bottom: 1.5rem;
}

.cart-invoice__text {
	font-size: 1.6rem;
	margin-bottom: 0.5rem;
}

.cart-invoice__notice {
	margin-top: 2rem;
	font-size: 1.6rem;
}

.cart-invoice__highlight {
	color: #00BAFF;
}
