@import '../../../src/styles/zIndex';
@import '../../../src/styles/media';

/* .pageMenu__canvas {
	position: absolute;
	top:61px;
	left:485px;
	width: 100vw;
	height: 100vh;
	position:fixed;
	display: flex;
	justify-content: left;
	background-color:rgba(255, 255, 255, 0);
	z-index:1010;
    flex-direction: rowrow;
} */

.pageMenu {
	// position: absolute;
	position:fixed;
	display: flex;
	flex-direction: row;
	margin-top: 0px;
	height:40px;
	background-color: #DDD;
	padding-top:0px;
	color:#FFF;
	z-index: z-index(forward)+10;
    -webkit-box-shadow: inset 1px 1px 0 rgba(0,0,0,.1), inset 0 -1px 0 rgba(0,0,0,.07);
    box-shadow: 0 3px 15px rgba(0,0,0,.1);
	transition: box-shadow 0.4s ease-in-out;
	//Desktop
	border-radius: 0 0 6px 6px;
	margin-left: 100px;
	@media ($tablet) {
		margin-left: 0px;
		border-radius: 0;
		width:100%;
		box-shadow: none;
	}
	@media ($mobile-l) {
		justify-content: flex-start;
	}
	&__menu-item {
		display: flex;
		flex-direction: row;
		padding: 0px;
		justify-content: center;
		position: relative;
		@media ($mobile-l) {
			width: 100%;
		}
		&>a,
		&>.page-menu-item__element {
			text-decoration:none;
			text-align: center;
			padding:0px;
			margin: 0px;
			cursor:pointer;
			white-space: nowrap;
		}
		.fa {
			color:#666;
			padding:11px 12px 0 17px;
			font-size: 1.7rem;
			height: 100%;
			@media ($tablet) {
				padding:14px 8px 0 14px;
				font-size: 1.4rem;
			}
		}
		/* BOX HIGHLIGHT COLOUR - darker blue background */
		&--highlight {
			background-color:#0099DD;
			border-radius: 0 0 0 6px;
			.fa,
			.pageMenu__menu-item--label {
				color:#FFF;
			}
		}
		&:hover {										// Hover Hack: removes hover from touch devices. Usually inherit colours as the ecape position.
			background-color:#00BAFF;
			.fa,
			.pageMenu__menu-item--label {
				color:#FFF;
			}
			@media (hover: none) {
				background-color: inherit;
				.fa,
				.pageMenu__menu-item--label {
					color: inherit;
				}
			}
		}
	}

	.pageMenu__menu-item--open {
		background-color: #00BAFF;
		.fa,
		.pageMenu__menu-item--label {
			color: #FFF;
		}
	}
}

/* ROUND FIRST AND LAST BOTTOM CORNERS */
.pageMenu__menu-item:first-child:hover,
.pageMenu__menu-item:first-child {
	border-bottom-left-radius: 6px;

	.fa {
		border-bottom-left-radius: 6px;
	}

	@media ($tablet) {
		border-radius: 0;
	}
}
.pageMenu__menu-item:last-child:hover,
.pageMenu__menu-item:last-child {
	border-bottom-right-radius: 6px;

	.pageMenu__menu-item--label {
		border-bottom-right-radius: 6px;
	}

	@media ($tablet) {
		border-radius: 0;
	}
}

.pageMenu__menu-item a span,
.pageMenu__menu-item div span {
	display:inline-block;
	padding: 12px 15px 0px 0px;
	font-size: 1.6rem;
	@media ($mobile-l) {
		padding: 14px 10px 0px 0px;
		font-size: 1.3rem;
	}
}
.pageMenu__menu-item--last {
	padding-right: 5px;
	border-radius: 0 0 5px 0;
}

.pageMenu__menu-item--label,
.pageMenu__menu-item .page-menu-item__element,
.pageMenu__menu-item a:visited {
	color:#666;
	text-decoration:none;
	font-size: 17.6px;
	height: 100%;
}

.pageMenu__menu-item--on {
	.fa,
	.pageMenu__menu-item--label {
		background-color: #0099DD;
		color:#FFFFFF;
	}
}

.pageMenu__menu-item--active {
	.fa,
	.pageMenu__menu-item--label {
		color: #6aa84f;
	}
	&:hover {										// Hover Hack: removes hover from touch devices. Usually inherit colours but in this case force to green.
		background-color:#6aa84f;
		.fa,
		.pageMenu__menu-item--label {
			color:#FFF;
		}
		@media (hover: none) {
			background-color: inherit;
			.fa,
			.pageMenu__menu-item--label {
				color: #6aa84f;
			}
		}
	}
}

/* Remove default padding between extra (tick) icon and text when publish is active */
.pageMenu__menu-item--active div:nth-child(2)>span {
	margin-right:0;
	padding-right:5px;
}
.pageMenu__menu-item--active div:nth-child(3)>i.fa {
	margin-left:0;
	padding-left:0;
}

.panel-wrapper {
	position: absolute;
	top: 4.7rem;
	right: 0;
	max-height: 60vh;
	background-color: #FFF;

	@media ($tablet) {
		overflow-y: scroll;
	}

	&:after {
		bottom: 100%;
		right: 4.5rem;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: rgba(136, 183, 213, 0);
		border-bottom-color: #FFF;
		border-width: 14px;
		margin-left: -14px;
	}
}

.panel {
	width: 47rem;
	padding: 2rem;
	display: flex;
	flex-direction: column;
	color: #666;
	box-shadow: 0 3px 15px rgba(0,0,0,.1);

	@media ($tablet) {
		width: 35rem;
	}

	@media ($mobile-l) {
		width: 30rem;
	}
}

.panel__title {
	font-size: 2.4rem;
	margin-bottom: 2rem;
}

.panel__row {
	display: flex;
	border-bottom: 0.1rem solid #EEE;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;

	&:last-child{
		border: none;
		justify-content: flex-end;
	}
}

.panel__product {
	flex: 1;
}

.panel__text {
	height: 100%;

	& div:first-child {
		padding-bottom: 0.5rem;
	}
}

.panel__button {
	height: 3rem;
	padding: 0;
	width: 15rem;
}

.panel__cancel-button {
	width: 20rem;
}

.panel__link {
	text-decoration: none;
	color: #00baff;
	padding-top: 0.5rem;
	padding-bottom: 1rem;
}
