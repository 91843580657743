@import '../../../src/styles/zIndex';

.payment {
	align-self: flex-end;
	width: 43rem;
	background-color: #FFF;
	padding: 2rem;
	font-size: 1.6rem;
}

.payment__input {
	margin-bottom: 1rem;

	&--short {
		width: 13rem;
	}

	.input__label {
		font-size: 1.6rem;
		color: #000;
	}

	.input {
		font-size: 1.6rem;
		height: 3.6rem;
		padding-left: 0.4rem;
	}
}

.payment__highlight {
	color: #00BAFF;
}

.payment__details {
	display: flex;

	.payment__input {
		margin-right: 3rem;
	}
}

.payment__buttons {
	display: flex;
	justify-content: space-between;
	margin-top: 1rem;

	.formButton {
		width: 17rem;
	}
}

.payment__error {
	width: 100%;
	margin-top: 1rem;
	padding: 1rem;
	border-radius: 0.5rem;
	color: white;
	word-wrap: wrap;
	text-align: center;
	border: 0.1rem solid #DDDDDD;
	color: rgb(255, 51, 0);
	background-color: #FFFFFF;
}
