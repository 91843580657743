@import '../../src/styles/colours';
@import '../../src/styles/media';

html {
	height: 100%;
	font-size: 62.5%;
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
}

body {
	// font-size: 14px;
	font-size: 1.4rem;

	height: 100%;
	margin: 0;
	padding: 0;
	font-family: sans-serif;
	position: relative;
}

h1 {
	//font-size: 24px;
	font-size: 2.4rem;
}

.app-loading-box {
	width: calc( 100% - 18px );
	height: 96vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.app {
	height: 100%;
	padding-top: 61px;
	min-width: 320px;  // screen will shrink below this width
}

@media ($tablet) {
	.app:not(.app--signed-in) {
		padding-top: 110px;
	}
}

.content {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	height: 100%;
	padding: 0;
	margin: 0;
}

.button-box {
	display: flex;
}

.global__button {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100px;
	margin: 0 3px 0 3px;
	color: #333333;
	background-color: #EEEEEE;
	border-radius: 5px;
	text-decoration: none;
	padding: 10px 0 10px 0;
	font-size: 1.2rem;
	cursor: pointer;
}

.global__button--blue {
	color: white;
	background-color:$primary-colour;

	.jbc-theme & {
		background-color:$jbc-go-colour;
	}
	.gla-theme & {
		background-color:$gla-go-colour;
	}

}

// DEFAULT LOGO SCHEMES
.logoScheme__ltl--slideBubble,
.logoScheme__img--elephant {
	fill: #00BAFF;
}
.logoScheme__ltl--name,
.logoScheme__img--name {
	fill: #000;
}

.logoScheme__jbc--hand,
.logoScheme__jbc--name {
	fill: $jbc-primary-colour;
}