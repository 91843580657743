@import '../../../src/styles/media';

.lifeTimeLines__page {
	display: flex;
	align-items: flex-start;
	height: 100%;
	width: 100%;
}

.lifeTimeLines {
	display: flex;
	flex-direction: row;
	align-content: flex-start;
	flex-wrap: wrap;
	width: 100%;
	background-color: #F0F0F0;
	min-height: 100vh;
}

.newRecord {
	width: 100%;
}

.formButton.newRecord__button {
	width: 100%;
}

.newRecord__title {
	font-size: 19pt;
}

.newRecord__self {
	font-size: 12pt;
	text-decoration: none;
	color: #FFFFFF;
}

.spinnerBox {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 364px;
	min-width: 364px;
	height: 350px;
	margin: 20px;
}

// @media ($desktop-s) {
// 	.recordBox {
// 		margin: 10px 5px;
// 	}
// }

@media ($tablet) {
	.lifeTimeLines {
		flex-wrap: nowrap;
		flex-direction: column;
		align-items: center;
	}
}



// WARNING - reinstating this will probably affect the home screen.
// Give the recordbox a secondary selector for the LTL screen
// @media ($mobile-m) {
// 	.recordBox {
// 		width: 320px !important;
// 		min-width: 320px !important;
// 		margin: 10px 0 !important;
// 	}

// 	.recordBox__frame--top {
// 		width: 320px !important;
// 		border-radius: none !important;
// 	}
// }
