@import '../../../src/styles/media';

.home-page {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100vh;
}

.main-slider {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #F0F0F0;
	height: 23vw;
	max-height:320px;
}
.main-slider__centred {
	display: flex;
	flex: 1 1 auto;
	justify-content: center;
	align-items: center;
	max-width: 1330px;
	//background-color: rgba(102, 1000, 100, 0.3);
}

.main-slider__textBlock {
	//display: flex;
	//flex-direction: column;
	flex: 0.8 1 auto;
	text-align: center;
	margin: 1rem 0;
	//background-color: rgba(255, 0, 255, .1);
}

.main-slider__logo--svg--box {
	//display: flex;
	flex: 1.2 1 auto;
	//background-color: rgba(255, 0, 0, .1);
	//padding: 8px 20px;
	//width: 40%;
}

.main-slider__logo--svg {
	max-height:270px;
	//height: 100%;
    max-width: 420px;
	height: 19vw;
	width: 29vw;					// various non chrome browsers require this.
	fill: #00baff;
	margin: 0 5.5vw 0 2.5vw;
	@media (min-width: 1330px) {  	// styles in here are at the max size and fixed.
		margin: 0 70px 0 30px;
	}
}


.main-slider__textBlock H1 {
	margin-top: 0;
	font-size: 3vw;
	@media (min-width: 1330px) {  // styles in here are at the max size and fixed.
		font-size: 40px;
	//	line-height: 40px;
		//background-color: rgba(100, 100, 100, .1);
	}
}

.main-slider__textBlock P {
	color: #888;
	font-size: 2.1vw;
	//line-height:1vw;
	margin: 0;
	@media (min-width: 1330px) {
		font-size: 28px;
		//line-height: 26px;
		//background-color: rgba(255, 0, 255, .1);
	}
	.regMark {
		font-size: 0.8vw;
		vertical-align: super;
	}
}

.home-actions {
	display: flex;
	margin-top: 2rem;
	min-height: 10rem;
	flex-direction: row;
	justify-content: center;
	max-width: 100%;
}

.home-actions__centred {
	display: flex;
	min-height: 5rem;
	max-width:1200px;
	padding:0 50px;
	@media ($tablet) {						// below table size, switch actions to column layout (one action above the next)
		flex-direction: column;
		min-height: 5rem;
		padding:0 10px;
	}
}
.home-actions__action {
	display:flex;
	flex-direction: row;
	margin: 1rem;
	cursor: pointer;
	max-width:346px;
	text-decoration: none;
	@media ($desktop-m) {					// below desktop-m size, switch action CONTENT to column layout (Icon above text block)
		flex-direction: column;
		align-items: center;
	}
	@media ($tablet) {						// below tablet size, switch action CONTENT BACK to row layout (Icon next to text block)
		flex-direction: row;
		align-items: start;
	}
}
.home-actions__action .fa-stack {
	flex: 0 0 auto;
	color: #999;
}

.home-actions__action .fa-circle {
	color: #DDD;
}

.home-actions__action:hover .fa-circle {
	color: #00BAFF;
}

.home-actions__action:hover .fa-stack {
	color: #FFF;
}

.home-actions__action--textBlock {
	display:flex;
	flex-direction: column;
	margin-left: 2rem;
	margin-right: 4rem;
	width: 100%;
	@media ($desktop-m) {
		align-items: center;
	}
	@media ($tablet) {
		margin-left: 1rem;
		margin-right: 1rem;
		align-items: left;
	}
}

.home-actions__action h2 {
	font-size: 1.92rem;
	color: #333;
	margin: 0.5rem 0;
	@media ($desktop-m) {
		text-align: center;
	}
	@media ($tablet) {
		text-align: left;
	}
}

.home-actions__action p {
	font-size: 1.44rem;
	color: #999;
	margin-top: 0.5rem;
}

.home-records__wrapper {
	display: flex;
	justify-content: center;
}

.home-records {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	height: auto;
	min-width: 30.7rem;
	max-width: 130rem;
}

.home-records__spinner {
	margin-bottom: 2rem;
}

.homeRecordBox {
	width: 28rem;
	min-width: 28rem;
	height: 26rem;
	background-color: #AAA;
	margin: 13.33px;
	padding: 0;
	border-radius: 0.6rem;
	background-color: #FFF;
}

.homeRecordBox .recordBox__frame--top {
	width: 28rem;
	height: 21rem;
	border-radius: 0.6rem 0.6rem 0 0;
	overflow: hidden;
	.formImage__wrapper {
		height: 21rem;
	}
}

.homeRecordBox .recordBox__frame--base {
	width: 28rem;
	border-radius: 0 0 0.6rem 0.6rem;
	background-color: #F5F5F5;
}

.homeRecordBox .recordBox__fullName {
	font-size: 12pt;
	color: #000;
	padding: 0.6rem 1rem;
}

.homeRecordBox .recordBox__years {
	font-size: 10pt;
	color: #666;
	padding: 0 1rem 7px 1rem;
}

.homeRecordBox .recordBox__image {
	max-width: 28rem;
}

.homeRecordBox .formImage--push-to-width {
	width: 28rem;
}

/* LAYOUT TESTING ##################################################
.main-slider--centred {	background-color:rgb(146, 204, 141);  }
.main-slider__logo {	background-color:rgb(70, 109, 161); }
.mainContent { background-color:rgb(204, 141, 190); }
.mainContent--centred {	background-color:rgb(146, 204, 141); }
.mainContent__actions {	background-color:rgb(35, 161, 161);  }
.home-actions__action { background-color:rgb(221, 241, 255); }
.home-actions__action { background-color:rgb(221, 241, 255); }
.home-actions__action .fa-stack { background-color:rgb(221, 100, 255); }
.home-actions__action--textBlock { background-color:rgb(100, 241, 255); }
.home-records {	background-color:rgb(170, 163, 64); }
.homeRecordBox { background-color:rgb(255, 4, 4); }
*/
/* LAYOUT TESTING ################################################## */


/* BROWSER SPECIFIC ################################################## */
/* TASK: Remove the IE 10+ hack below */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.home-actions__action {
		width: calc( ( (100% - 4rem)/3 ) - 1px );
	}
}
/* TASK: Remove the IE Edge hack below */
@supports (-ms-ime-align: auto) {
	.home-actions__action {
		width: calc( ( (100% - 4rem)/3 ) - 1px );
	}
}
/* BROWSER SPECIFIC ################################################## */




@media ($tablet) {
	.home-records {
		max-width: 100%;
	}
}

