@import '../../../src/styles/media';
@import '../../../src/styles/zIndex';

.user_menu__canvas {
	position: absolute;
	top:0;
	left:0;
	width: 100vw;
	height: 100vh;
	background-color:rgba(255, 255, 255, 0);
	z-index: z-index(modal);
}

.user_menu {
	display: flex;
	flex-direction: column;

	margin-top: 6.1rem;
	width: 8.6rem;
	height: 100%;

	background-color: #FAFAFA;

	z-index:1100;

	box-shadow: 0 10px 15px rgba(0,0,0,.1);
	transition: box-shadow 0.4s ease-in-out;
}

@media ($tablet) {
	.app:not(.app--signed-in) .user_menu {
		margin-top: 110px;
	}
}

.menu-item {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 8.6rem;
	height: 6.5rem;
	border-bottom: 1px solid #F5F5F5;
	text-decoration:none;
}

.menu-item--responsive {
	display: none;
}

@media ($tablet) {
	.menu-item--responsive{
		display: flex;
	}
}

.user_menu__logo {
	height: 2.7rem;
	width: 6.6rem;
	stroke: #999999;
	fill: #999999;
}

.menu-item__icon {
	color: #999999;
	font-size: 2.56rem;
}

.menu-item__label {
	color: #999999;
	font-size: 0.96rem;
}


.menu-item:hover {
	background-color:#00BAFF;

	.user_menu__logo,
	.menu-item__icon,
	.menu-item__label {
		color:#FFFFFF;
		stroke: #FFFFFF;
		fill: #FFFFFF;
	}
}
