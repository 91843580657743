.message__canvas {
	display: flex;
	position: absolute;
	top:0;
	width: 100vw;
	height: 100vh;
	position:fixed;
	justify-content: center;
	background-color:rgba(42, 42, 42, 0.71);
	z-index: 1010;
}

.message__content {
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	min-width: 400px;
	min-height: 200px;
	background-color: #FFF;
	padding: 20px;
	margin-top: 65px;
	border-radius: 5px;
	border: solid 1px #CCC;
	color:#444;
	z-index: 1100;
	position: fixed;
}
.message__title {
	padding-bottom: 5px;
	font-size: 19.2px;
}
.message__description {
	padding-bottom: 15px;
	font-size: 14.4px;
}
.message__detail {
	color:#AAA;
	padding-bottom: 25px;
	font-size: 14.4px;
}
.button-box{
	display: flex;
}

.message__email {
	font-size: 14.4px;
	color:#CCC;
	text-decoration:none;
}
.message__email:hover {
	text-decoration:underline;
}
// .message__canvas {		background-color: rgba(125, 0, 0, 0.411);		}
// .message__content {		background-color: #CFF;							}
// .message__title {		background-color: #CFC;							}
// .message__description {	background-color: #FFC;							}
// .message__detail {		background-color: #FCC;							}
// .button-box{			background-color: #CCF;							}
// .message__email {		background-color: #CFC;							}
// .message__email:hover {	background-color: #555;							}