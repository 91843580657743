@import '../../../src/styles/media';

.lifeTimeLine {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	width: 100%;
	background-color: #F3F3F3;
	position: relative;
	@media ($tablet) {
		flex-direction: column;
	}
}

.moments-panel {
	display: flex;
	justify-content: flex-start;
	min-height: calc(100vh - 61px);
	width: calc(100% - 494px); 												// stops (at least one reason) for base scroll bar appearing when vertical scroll required.
	position: relative;
	left: 464px;

	@media ($tablet) {
		width: 100%;
		left: 0;
		top: 130px;

		.summaryPanel:not(.summaryPanel--edit) + &.moments-panel--author {	// when <= tablet & author but not actually editing...
			top: 180px;
		}

		.summaryPanel.summaryPanel--edit + &.moments-panel--author {		// when <= tablet & author and editing...
			top: 400px;
		}
	}

	@media ($mobile-l) {													// required because we change .summaryPanel position to relative for mobiles to allow scrolling
		.summaryPanel.summaryPanel--edit + &.moments-panel--author {		// when <= tablet & author and editing...
			top: 280px;
		}
	}
}