.switch__wrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	position: relative;
}

.switch__input {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 3.6rem;
}

.switch {
	height: 2.4rem;
	width: 4.5rem;
	background-color: #999;
	border-radius: 1.2rem;
	outline: none;
	cursor: pointer;

	&--open {
		background-color: #6AA84F;
	}

	&:focus {
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(0, 186, 255, 0.6);
	}
}

.switch__button {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 2rem;
	width: 2rem;
	margin: 0.2rem;
	margin-right: 2.3rem;
	border-radius: 1rem;
	background-color: #FFF;
	transition: margin 0.1s ease-out;

	.switch--open > & {
		margin-left: 2.3rem;
		margin-right: 0.2rem;
	}
}

.switch__lock {
	font-size: 1.5rem;
	color: #999;

	.switch--open & {
		color: #6AA84F;
	}
}


.switch__tooltip {
	display: block;
	border: 1px solid #dddddd;
	position: absolute;
	left: 6rem;
	top: -1.7rem;
	opacity: 1;
	width: 20rem;
	padding: 1rem;
	font-size: 1.2rem;
	background-color: #FFFFFF;
	right: 1rem;
	color: #333333;

	// Tooltip pointer
	&:after, &:before {
		right: 100%;
		top: 50%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
	}
	&:after {
		border-color: rgba(255, 255, 255, 0);
		border-right-color: #ffffff;
		border-width: 10px;
		margin-top: -10px;
	}
	&:before {
		border-color: rgba(221, 221, 221, 0);
		border-right-color: #dddddd;
		border-width: 11px;
		margin-top: -11px;
	}
}