//@import '../../../src/styles/zIndex';
@import '../../../src/styles/media';

.pageMenu--lifeTimeLine {
	margin-left: 480px;
	@media ($tablet) {
		margin-left: 0px;

	}
}

