@import '../../../src/styles/media';


.moment {
	// Do not add borders here as the border will bush image sideways. Borderes have been tested successfully on the moment_card selector.
	margin: 20px 5px 5px 20px;
	background-color: #FFF;

	border-radius: 6px;
	clear: both;
	max-width: 364px;
	flex: 0 0 auto;
	@media ($tablet) {
		margin: 5px;
	}
	@media ($mobile-m) {
		width: 320px;
		margin: 0;
	}
}

.moment__link {
	text-decoration: none;
}

.moment__image {
	width: 364px;
	height: 273px;
	overflow: hidden;
	margin: 0;
	border-radius: 6px 6px 0 0;
	background-color: #EEE;
	@media ($mobile-m) {
		width: 320px;
		height: 240px;

		.formImage__wrapper {
			width: 320px;
			height: 240px;
		}
		.formImage--push-to-width {
			width: 320px;
		}
	}
}

.moment__card {
	padding: 20px 20px 20px 20px;
	// border-left: 1px solid #EEE;
	// border-right: 1px solid #EEE;
	// border-bottom: 1px solid #EEE;
	// border-radius: 0 0 6px 6px;
}

.moment__footer {
	display: flex;
	flex-direction: row;
}

.moment__datetime {
	color: #00BAFF;
	margin-right: 6px;
}

.moment__datetime--circa {
	color: #CCC;
	margin-right: 6px;
	cursor: pointer;
}

.moment__data {
	display: flex;
	flex-direction: row;
}

.moment__shortDescription {
	max-width: 324px;
	font-size: 11pt;
	color: #888;
	flex: 1 1 auto;
	height: 32px;
	overflow: hidden;
	position: relative;
	line-height: 16px;
}

.moment__shortDescription--clippedLink {
	color:#00BAFF;
	position: absolute;
	top: 16px;
	left: 260px;
	//background-color: #FFF;
	padding-left: 15px;
	background: linear-gradient( to right,rgba(255,255,255,0), white 20%);
	//background: linear-gradient(to right, rgba(255,255,255,0) 10%, rgba(255,255,255,1) 90%);
}

.moment__metaData {
	margin-top: 15px;
	width: 100%;
	color: #999999;
	text-align: right;
}

.moment__metaData>.fa {
	margin-left: 20px;
	margin-right: 0px;
}

.moment__metaData>.fa:hover {
	color: #00BAFF;
	cursor: pointer;
}

.small-moment__edit,
.small-moment__settings,
.small-moment__delete {
	color: #999999;
	margin-left:20px;
}

.small-moment__edit:hover,
.small-moment__settings:hover {
	color: #00BAFF;
}

.small-moment__delete:hover {
	color: rgb(255, 60, 0);
}
