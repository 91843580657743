@import '../../../src/styles/media';


.account__page {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	min-height: calc(100vh - 61px);
	background-color: #FAFAFA;
	position: relative;
	&--image {
		margin-top:8px; // Aligns top of image with top of label in first input field to its right on the desktop.
	}
	@media ($tablet) {
		flex-direction: column;
	}
}
.account__page--title {
	display: flex;
	flex: 0 0 auto;
	width: calc(100% - 200px);
	margin: 80px 0 0 100px;
	height: 6rem;
	font-size: 4.5rem;
	max-width: 795px;
	@media ($tablet) {
		width: calc(100% - 110px);
		margin: 60px 0 0 50px;
		font-size: 3.5rem;
	}
	@media ($mobile-l) {
		width: 100%;
		margin: 60px 0 0 0;
	}
	border-bottom: solid 1px #DDD;
}

.account__page--form {
	display: flex;
	flex-direction: row;
	flex: 0 0 auto;
	max-width: 795px;
	width: calc(100% - 200px);
	margin: 20px 0 0 100px;
	@media ($tablet) {
		width: calc(100% - 110px);
		margin: 20px 0 0 50px;
	}
	@media ($mobile-l) {
		flex-direction: column;
		width: 100%;
		max-width: 364px;
		margin: 10px auto;
	}
}
.account__page--inputs {
	display: flex;
	flex-direction: column;
	flex: 1 0 auto;
	padding: 0px 15px 15px 15px;
	max-width: 430px;
	.account__page--input {
		display: flex;
		flex-direction: column;
		flex: 0 0 auto;
		margin:2px;
		&Set {										// input button set in a row. e.g. verify email
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			button {
				padding: 10px 26px;
				margin-top:3px;
				@media ($mobile-l) {
					padding: 10px 16px;
				}
			}
			.formButton__spinner {
				top: 6px;
			}

		}
		p.top {
			margin: 0px 0 28px 0;
		}
		p.base {
			margin: 20px 0 8px 0;
		}
	}
	.input__flag {
		align-self: flex-end;
		color: #00BAFF;
		* {
			margin-left: 5px;
			margin-top: 3px;
		}
		&--warn {
			color: rgb(202, 49, 3);
		}
	}
}



.inputField {
	outline: none;
	margin-bottom: 15px;
	font-size:1.2rem;
	color:#555;
	width:100%;
	&--short40 {
		width:40%;
	}
	&--short50 {
		width:50%;
	}
	.input__label {
		color:#999;

	}
	.input {
		margin: 0 0 -1px 0;
		padding: 9px 10px 9px 8px;
		outline: none;
		border: 1px solid #E5E5E5;
		font-size:1.8rem;
	}
}


.account__page--buttons {
	display: flex;
	flex-direction: row;
	flex: 0 0 auto;
	justify-content: flex-end;
	max-width: 795px;
	width: calc(100% - 200px);
	margin: 20px 0 0 100px;
	padding-bottom: 10px;
	padding-right: 17px;
	@media ($tablet) {
		width: calc(100% - 110px);
		margin: 20px 0 0 50px;
	}
	@media ($mobile-l) {
		width: 100%;
		max-width: 364px;
		margin: 10px auto;
		justify-content: space-between;
	}
	border-bottom: solid 1px #DDD;
	button {
		margin-left:15px;
		padding: 13px 26px;
	}
	.formButton--status {
		width:200px;
	}
}

.formButton--toggle:focus { 						// Hides border on clicked toggle button
	box-shadow: none;
	outline: 0 none;
	border: 1px solid transparent;
}

//input__label
// .account__page--form 	{		background-color: rgba(0, 255, 0, 0.39);		}
// .account__page--inputs 	{		background-color: rgba(255, 85, 170, 0.534);	}
// .account__page--input 	{		background-color: rgba(2, 141, 255, 0.534);		}