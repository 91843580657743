@import '../../../src/styles/zIndex';

.full-screen-moment {
	.formImage--portrait {
		margin: 0;
	}

	&__description {
		position: absolute;
		width: 100%;
		background-color: #FFF;
		padding: 1rem;
	}

	&--edit {
		// Matches the height of edit panel
		margin-bottom: 24.1rem;
	}
}

.full-screen-moment__controls {
	display: flex;
	justify-content: space-around;
	align-items: flex-end;
	z-index: z-index(foreground);

	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 6.5rem;
	background: linear-gradient(transparent, rgba(0, 0, 0, 0.1));
	transition: bottom 0.2s ease-out;

	&--closed {
		bottom: -6.5rem;
	}
}

.full-screen-moment__control-item {
	margin: 1rem 3rem;
	display: flex;
	justify-content: center;
	align-items: center;

	width: 3rem;
	height: 3rem;
	font-size: 2.5rem;
	color: white;
	text-shadow: 1px 2px 1px black;
	cursor: pointer;
}

.full-screen-moment__share {
	position: relative;
	top: unset;
	right: unset;
	font-size: unset;
	color: unset;
}

.full-screen-moment__description {
	width: 100vw;
	z-index: z-index(forward);

	position: absolute;
	left: 0;
	bottom: 5rem;
	transition: bottom 0.3s ease-in-out;

	&--closed {
		bottom: -30rem;
	}

	&--edit {
		bottom: 0rem;
	}
}
