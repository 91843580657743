@import '../../../src/styles/media';

.search__page {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: calc(100vh - 110px);
	.search__results {
		width: 100%;
	}
	.search {
		@media ($tablet) {
			width: 30rem
		}
	}
	.content {
		margin-top: 5rem;
	}
}

.page-content--search {
	padding-top:10px;
	// .search {
	// 	@media ($tablet) {
	// 		width: 96%
	// 	}
	// }
	.search__button {
		display: none;
	}


}

