
.activityFeedItems {
	margin-top:15px;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-top: solid 1px #F0F0F0;
	background-color:#FFF;
	color: #AAA;
}

.activityFeed__item {
	padding:10px 0px 10px 0px;
	font-size: 1.3rem;
	width:calc(100% - 60px);
	color: #AAA;
	display: flex;
	flex-direction: row;
	border-bottom: solid 1px #F0F0F0;
	//background:yellow;
}

// .activityFeed__divider {
// 	background-color: #F0F0F0;
// 	height:1px;
// 	width:calc(100% - 40px);
// 	margin-left: 20px;
// 	margin-top: 15px;
// }

.activityFeed__item--icon {
	color: #DADADA;
	width:30px;
	margin-right:10px;
	> i {
		font-size: 24px;
	}
}

.activityFeed__item--data {
	display: flex;
	flex-direction: column;
	width:calc(100% - 20px);
}
.activityFeed__item--content {
	color: #222;
}
.activityFeed__item--details {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.activityFeed__item--author {
	color: #00BAFF;
}
.activityFeed__item--dateTime {
	color: #BBB;
	font-size:1rem;
	margin-top:3px;
}