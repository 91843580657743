.formButton {
	position: relative;
	padding: 13px 26px;
	font-size: 12pt;
	border: none;
	border-radius: 5px;
	cursor: pointer;

	color: #333333;
	background-color: #EEEEEE;
	outline:none;
	border: solid 1px transparent;
}

.formButton:focus {
	/* border-color: rgba(0, 186, 255, 0.8); */
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(0, 186, 255, 0.6);
	outline: 0 none;
	border:solid 1px #00BAFF;
}

.formButton--blue {
	color: #FFFFFF;
	background-color: #00BAFF;
}

.formButton--blue:focus {
	/* border-color: rgba(0, 186, 255, 0.8); */
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(122, 122, 122, 0.6);
	outline: 0 none;
	border:solid 1px #00BAFF;
}

.formButton--green {
	color: #FFFFFF;
	background-color: rgb(88, 194, 1);
	&:focus {
		box-shadow: none;
		outline: 0 none;
		border: 1px solid transparent;
	}
}
.formButton--grey {
	color: #333333;
	background-color: #CCCCCC;
}
.formButton--midGrey {
	color: #FFFFFF;
	background-color: #AAAAAA;
}

.formButton--darkGrey {
	color: #FFFFFF;
	background-color: #777777;
}

.formButton--red {
	color: #FFFFFF;
	background-color: #CC0000;
}

.formButton--red:focus {
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(122, 122, 122, 0.6);
	outline: 0 none;
	border:solid 1px #CC0000;
}

.formButton--dark-blue {
	color: #FFFFFF;
	background-color: #2b78ef;
}

.formButton--disabled {
	color: #333333;
	background-color: #CCCCCC;
}

.formButton__spinner {
	position: absolute;
	top: 11px;
	right: 11px;
	width: 27px;
	height: 27px;
}


