@import '../../../src/styles/media';
@import '../../../src/styles/zIndex';

/*	Main Header Box (sticky) - contains the three lelments (brand) (search) (menu) */
.header {
	display: flex;
	justify-content: space-between;
	height: 61px; 							// Search headerOffset-Height - Noted in utils/general.js
	padding: 10px 15px 10px 0;
	position:fixed;
	width: 100%;
	background-color: #FFF;
	color: #333;
	border-bottom:solid 1px #EFEFEF;
	top:0px;
	z-index: z-index(foreground);
}

.header--shadow {
	box-shadow: 0 3px 15px rgba(0,0,0,.1);
	transition: box-shadow 0.4s ease-in-out;
}

.header__leftMenuAndBrand {
	display: flex;
}



/*	Main header brand box (left) */
.brand {
	display: flex;
	align-items: center;
	text-decoration: none;
}

.brand__logo {
	height: 40px;
	//width:40px;
	margin-left: 10px;
}

.brand__title {
	margin-left: 10px;
	font-family: Verdana, Arial, Helvetica;
	font-size: 22pt;
	color: black;
	@media ($mobile-s) {
		font-size: 18pt;
	}
}

.right-menu {
	display: flex;
	padding: 0 1rem;
	background-color: #FFFFFF;
	max-width: 320px;

	.right-menu__cart {
		width: 5rem;
	}
}

.left-menu {
	padding:0;
	margin:0;
	background-color:#FFF;
	text-align:center;
	width: 6.6rem;
	padding-left: 2rem;
}
.left-menu__empty {
	width: 2rem;
}
.left-menu>.fa,
.right-menu>a>i,
.right-menu>a:visited>i {
	color:#999999;
	margin: 7px 7px;
	font-size:25.6px;
	position: relative;
}
.left-menu:hover {
	cursor: pointer;
}
.right-menu>a>i:hover {
	color:#00BAFF;
}

/* i::before, i::after {
  content:"\00a0";
} */

.badge {
	font-size: 10.24px;
	font-family:arial;
	display: block;
	position: absolute;
	top: 18.432px;
	right: -7.168px;
	width: 18.422px;
	height: 18.422px;
	line-height: 20.48px;
	border-radius: 50%;
	text-align: center;
	color: #fff;
	background: rgba(0,180,255,0.9);
}

.badge-lg {
	top: 17.408px;
	right: -6.144px;
	width: 19.453px;
	height: 19.453px;
	line-height: 21.48px;
}
@media ($tablet) {
	.app:not(.app--signed-in) .header {
		flex-direction: column;
		align-items: center;
		height: 110px;
		padding: 10px 15px;
	}
	.summaryPanel {
		top: 110px;
	}
	.user_menu__content {
		margin-top: 110px;
	}
	.right-menu .right-menu__cart {
		display: none;
	}
}