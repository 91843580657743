@import '../../../src/styles/zIndex';

.backdrop {
	display: flex;
	justify-content: center;

	position: fixed;
	top: 0;
	left: 0; /*IE11*/
	width: 100vw;
	height: 100vh;
	z-index: z-index(modal);
	background-color:rgba(0, 0, 0, .5);
}