.inputGroup .textInput,
.inputGroup .select {
	border-radius: 0;
	border-bottom: none;
}

.inputGroup .textInput:focus,
.inputGroup .select:focus {
	border-color: #DDDDDD;
	box-shadow: none;
}

.inputGroup .select-wrapper:first-child .select:focus {
	border-bottom: none;
}
.inputGroup .select-wrapper:last-child .select:focus {
	border-top: none;
}

.inputGroup .select-wrapper:first-child,
.inputGroup .select-wrapper:first-child .select,
.inputGroup .textInputWrapper:first-child .textInput {
	border-radius: 5px 5px 0 0;
}

.inputGroup .select-wrapper:last-child,
.inputGroup .select-wrapper:last-child .select,
.inputGroup .textInputWrapper:last-child .textInput {
	border-radius: 0 0 5px 5px;
	border-bottom: 1px solid #DDDDDD;
}
