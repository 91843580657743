@import '../../../src/styles/colours';
@import '../../../src/styles/media';

.focusBox {
	display: flex;
	flex-direction: row;
	margin-top:5rem;
	width: 35rem;
	@media ($mobile-l) {
		flex-direction: column;
	}
	.focusBox__left {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		flex: 0 0 1;
		padding: 4rem 1rem 5rem 1rem;
		border: 0.1rem solid $primary-colour;
		border-radius: 0.6rem;
		background-color: $primary-colour;

		.jbc-theme & {
			border: 0.1rem solid $jbc-primary-colour;
			background-color: $jbc-primary-colour;
		}
		.gla-theme & {
			border: 0.1rem solid $gla-primary-colour;
			background-color: $gla-primary-colour;
		}
	}
	.focusBox__right {
		flex: 0 0 1;
		color: #999999;
		padding: 4rem 1rem 5rem 1rem;
		border: 0.1rem solid #E0E0E0;
		border-radius: 0 0.6rem 0.6rem 0;
		background-color: #FFFFFF;
	}
}

.focusBox--twin {
	width: 64rem;
	> .focusBox__left {
		border-radius: 0.6rem 0 0 0.6rem;
		@media ($mobile-l) {
			border-radius: 0.6rem 0.6rem 0 0;
		}
	}
	> .focusBox__right {
		border-radius: 0 0.6rem 0.6rem 0;
		@media ($mobile-l) {
			border-radius: 0 0 0.6rem 0.6rem;
		}
	}
	@media ($mobile-l) {
		width: 35rem;
	}
}

.focusBox__logo {
	height: 7.4rem;
	margin-bottom: 2rem;
	// LOGO SCHEMES -theme
	.logoScheme__ltl--slideBubble,
	.logoScheme__ltl--name,
	.logoScheme__img--elephant,
	.logoScheme__img--name,
	.logoScheme__jbc--hand,
	.logoScheme__jbc--name {
		fill: #FFF;
	}
	// -theme add height just for Glenaeon
	.gla-theme & {
		height: 10.4rem;
	}
}

.focusBox__buttons {
	display: flex;
	justify-content: space-between;
}

.formButton.focusBox__button {
	width: 29rem;
	color: #FFFFFF;
	background-color: $primary-colour-deep;
	border: 0.1rem solid $primary-colour-deep;
	margin-top: 2rem;
	padding: 1.5rem;

	.jbc-theme & {
		background-color: $jbc-primary-colour-deep;
		border: 0.1rem solid $jbc-primary-colour-deep;
	}
	.gla-theme & {
		background-color: $gla-primary-colour-deep;
		border: 0.1rem solid $gla-primary-colour-deep;
	}
}

.formButton.focusBox__button:hover {
	background-color: $primary-colour-dark;
	border: 0.1rem solid $primary-colour-dark;

	.jbc-theme & {
		background-color: $jbc-primary-colour-dark;
		border: 0.1rem solid $jbc-primary-colour-dark;
	}
	.gla-theme & {
		background-color: $gla-primary-colour-dark;
		border: 0.1rem solid $gla-primary-colour-dark;
	}
}

.focusBox__buttons--cancelable {
	.focusBox__button {
		margin-top: 1rem;
		height: 4.5rem;
	}

	.focusBox__cancel {
		margin-top: 1rem;
		margin-right: 1rem;
		padding: 1rem;
		height: 4.5rem;
	}
}

.focusBox__errorMessage {
	width: 100%;
	margin-top: 2rem;
	padding: 1rem;
	border-radius: 0.5rem;
	color: white;
	word-wrap: wrap;
	text-align: center;
	border: 0.1rem solid #DDDDDD;
	color: rgb(255, 51, 0);
	background-color: #FFFFFF;
}

.bottom-link {
	position: absolute;
	bottom: 0;
	left: 0;
	text-align: center;
	margin: 1rem 0;
	width: 100%;
	color: #FFFFFF;
	text-decoration: none;
	font-size: 1.3rem;
    padding: 0 40px;
}

.focusBox__description {
	height: 100%;
}

.focusBox__title {
	text-align: center;
	font-size: 2.4rem;
	margin-bottom: 2rem;
}

.focusBox__text {
	text-align: center;
}