.verify {
	.focusBox__left {
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 0;
		border: none;
	}

	.verify__content {
		background-color: #FFFFFF;
		border: 0.1rem solid #EEEEEE;
		border-top: none;
		border-radius: 0 0 0.5rem 0.5rem;
		padding: 4rem;
		padding-top: 2rem;
	}

	.verify__buttons {
		display: flex;
		justify-content: center;
		margin-top: 2rem;
	}

	.verify__button {
		width: 12rem;
	}

	.verify__message {
		margin-top: 2rem;
		text-align: center;
		border: 1px solid green;
		color: green;
		background-color: #EEE;
		padding: 0.5rem;
		border-radius: 5rem;

		&--error {
			color: #CC0000;
			border-color: #CC0000;
		}
	}
}

.verify__loader {
	margin-top: 3rem;
}
