@import '../../../src/styles/media';

.moments {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-items: flex-start;
	align-content: flex-start;
	margin-left: 10px;
	width: 100%; /* marry with settings in moment.css */
	position: relative;

	@media ($mobile-m) {
		margin-left: 0;
	}
}

.tutorial {
	display: flex;
	flex-direction: column;
	width: 44rem;
	margin: 4rem;

	@media ($desktop-s) {
		margin-top: 0;
	}

	@media ($mobile-l) {
		margin-top: 8rem;
	}
}

.tutorial__item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 1rem 0;
}

.tutorial__text {
	display: flex;
	align-items: center;
	color: #333333;
	font-size: 2rem;
}

.tutorial__icon {
	display: flex;
	justify-content: center;
	width: 4rem;
	font-size: 2.5rem;
	margin-right: 1.5rem;
}

.tutorial__tick {
	color: #DDD;
	font-size: 3rem;
	margin-left: 2rem;

	&--active {
		color: #00BAFF;
	}
}

.tutorial__show {
	color: #666666;
	cursor: pointer;
}

.tutorial__buttons {
	display: flex;
	align-items: center;
}
